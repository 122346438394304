import { useMemo, useEffect, useState, useCallback, useRef, Fragment } from 'react'
import 'intl-pluralrules'
import App from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import posthog from 'posthog-js'
import type { ReactNode } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import type { AppProps, AppContext } from 'next/app'
import cookie from 'cookie'
import uuid from 'uuid-random'
import { ApolloProvider } from '@apollo/client'
import { ErrorBoundary } from 'react-error-boundary'
import qs from 'query-string'

import { YellowChatWidget } from 'components/chat-widget/yellowai'
import { ModalOverlay } from 'components/modal-overlay'
import { ErrorFallback } from 'components/error-fallback'
import { AccountCreatedToast } from 'components/account-created-toast'
import { MarketingConsentModal } from 'components/marketing-consent-modal'
import { GoogleOneTap } from 'components/google-one-tap'
import { ExitIntentEvents } from 'components/exit-intent-events'
import { ToastContainer } from 'components/toast-container'

import { WishlistModal } from 'page-modules/product/wishlist/modal'

import { useCurrencyForIsrPage } from 'lib/hooks/useCurrencyForIsrPage'
import useEventSessionAndRef from 'lib/hooks/useEventSessionAndRef'

import { removeExpiredStaticFields } from 'lib/utils/booking'
import getStripe from 'lib/utils/stripe'
import { sift } from 'lib/utils/sift'
import {
  createActivitySession,
  getBrowserCookie,
  getQueryLists,
  getStringifiedUtmParams,
  isBrowser,
  logError,
  serialiseCookie,
  setBrowserCookie,
} from 'lib/utils'
import { FingerprintContextProvider } from 'lib/context/fingerprint-context'
import { HeaderDataProvider } from 'lib/context/header-data-context'
import { GlobalContextProvider, IGlobalContext, initialGlobalArgs } from 'lib/context/global-context'
import { getStorageItem } from 'lib/utils/web-storage'
import { initializeApollo, useApollo } from 'lib/apollo-client'
import { AppDataProvider } from 'lib/context/app-data-context'
import { AuthProvider } from 'lib/context/auth-context'
import { WishlistProvider } from 'lib/context/wishlist-context'
import { LayoutProps, NextPageWithLayout } from 'lib/@Types/layout'
import { DestinationByRegionProvider } from 'lib/context/destination-by-region-context'

import {
  COOKIES_TOKEN,
  FALLBACK_CURRENCY,
  COOKIES_CURRENCY,
  COOKIES_DS_USER_ID,
  UTM_STORAGE_KEY,
  UTM_COOKIE_EXPIRATION,
  IS_MOBILE_REGEX,
  IS_TABLET_REGEX,
  HEADER_USER_AGENT,
  HEADER_CLOUNDFRONT_IS_MOBILE_VIEWER,
  HEADER_CLOUNDFRONT_IS_TABLET_VIEWER,
  COOKIES_DS_SESSION_ID,
  SENTRY_ERROR_IGNORE_REGEX,
  HEADER_CLOUDFRONT_LATITUDE,
  HEADER_CLOUDFRONT_LONGITUDE,
  HEADER_CLOUDFRONT_COUNTRY,
  HEADER_REFERER,
  REFERER_STORAGE_KEY,
  REFERER_COOKIE_EXPIRATION,
  ACTIVITY_LOG,
  ACTIVITY_LOG_EXPIRY,
  COOKIES_RECENT_PDP_VISIT,
  COOKIES_RECENT_PDP_VISIT_EXPIRY,
  COOKIES_LAST_CLICK,
  COOKIES_LAST_CLICK_EXPIRATION,
  COOKIES_FIRST_CLICK,
  COOKIES_FIRST_CLICK_EXPIRATION,
  HEADER_CLOUDFRONT_CITY,
  COOKIES_LAST_30_CLICK,
  COOKIES_LAST_30_CLICK_EXPIRATION,
  COOKIES_PARTNER_SESSION_ID,
  PARTNER_DEFAULT_COUNTRY_CODE,
  PARTNER_DEFAULT_CURRENCY,
  PARTNER_SESSION_EXPIRY,
  PARTNER_LIST,
} from 'lib/constants'
import { STATIC_ROUTES } from 'lib/constants/routes'

import { GLOBAL_ARGS_QUERY_AND_CAMPAIGN } from 'gql'

import localeInfo from '../locale-info.json'
import { useYellowAi } from '../lib/hooks/useYellowAi'

import './global.scss'
import 'brand-assets/icon-lib/icon-custom-styles.scss'

const { publicRuntimeConfig } = getConfig()

if (isBrowser) {
  smoothscroll.polyfill()
}

const AuthModal = dynamic(() => import('components/auth-modal').then((module: any) => module.AuthModal), {
  ssr: false,
})
const ProgressBar = dynamic(
  () => import('../lib/context/progress-bar-context').then((module: any) => module.ProgressBar),
  {
    ssr: false,
  }
)

interface EnhancedAppProps extends AppProps {
  ssrIsMobile: boolean
  ssrIsTablet: boolean
  ssrIsLoggedIn: boolean
  ssrPartnerSessionInfo: Record<string, any> | null
  currentCurrency: string
  globalArgs: GlobalArgs
  geoLocation: GeoLocation
  campaignData: CampaignData
  Component: NextPageWithLayout
}

if (isBrowser) {
  createActivitySession()
  // fix: https://stackoverflow.com/questions/51625973/datalayer-push-not-working-after-gtm-script
  window.dataLayer = window.dataLayer || []
}

if (isBrowser) {
  // remove expired data from local storage for static fields
  removeExpiredStaticFields()
}

if (isBrowser) {
  const _activityLog: any = getStorageItem(ACTIVITY_LOG)
  let isPDPVisitedRecently = false

  try {
    const jsonActivityLog = JSON.parse(_activityLog) || {}

    isPDPVisitedRecently = !!jsonActivityLog?.products?.filter?.(
      ({ created }: { created: Date }) =>
        new Date().getTime() - new Date(created).getTime() <= ACTIVITY_LOG_EXPIRY
    )?.length
  } catch {}
  setBrowserCookie(COOKIES_RECENT_PDP_VISIT, `${isPDPVisitedRecently}`, COOKIES_RECENT_PDP_VISIT_EXPIRY)
}

if (isBrowser) {
  const searchParams = qs.parse(window.location.search)
  // to best leverage Stripe’s advanced fraud functionality, include this script on every page
  // https://stripe.com/docs/js/including
  // Dont load in partner view
  if (PARTNER_LIST.includes(searchParams?.partnerId as string)) {
    getStripe()
  }

  const [browserLocale] = window?.location?.pathname?.split?.('/')?.filter(Boolean)
  const validBrowserLocale =
    localeInfo.supportedLocales?.find?.((l) => l?.toLowerCase?.() === browserLocale?.toLowerCase?.()) ||
    localeInfo.fallbackLocale
  // @ts-ignore
  const polyfillLocale = localeInfo.localeToPolyfillLocale[validBrowserLocale] || validBrowserLocale

  const unsupportedLocale = shouldPolyfill(polyfillLocale)
  const includeLocalePolyfill = shouldPolyfillLocale()

  ;(async () => {
    if (!unsupportedLocale && !includeLocalePolyfill) return

    // polyfill added for ios 12 iphone 7 device
    // docs https://formatjs.io/docs/polyfills/intl-numberformat
    try {
      // eslint-disable-next-line @next/next/no-assign-module-variable
      const module = await import('./intl-polyfills')
      module.default({ unsupportedLocale, includeLocalePolyfill })
    } catch {
      logError(`[${unsupportedLocale}] polyfill not supported (original: ${polyfillLocale})`)
    }
  })()
}

if (isBrowser && process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  // We will add posthog_key on production only for now.
  // As we dont have sandbox account for posthog and we want to test
  // on dev, we can add key for temoprary purpose and remove later
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
  })
}

let countryToCurrency: any
const getCountryToCurrency = async () => {
  if (!countryToCurrency) {
    countryToCurrency = (await import('country-to-currency')).default
  }
  return countryToCurrency
}

function MyApp({
  Component,
  pageProps,
  globalArgs,
  geoLocation,
  currentCurrency,
  campaignData,
  ssrIsTablet,
  ssrIsLoggedIn,
  ssrPartnerSessionInfo,
}: EnhancedAppProps) {
  const router = useRouter()

  const isPartnerView = ssrPartnerSessionInfo?.partnerId || ssrPartnerSessionInfo?.sessionId
  const [isMobileView, setIsMobileView] = useState<boolean>(true) // defaults to true as most of users are from mobile phone
  const [isTabletView, setIsTabletView] = useState(ssrIsTablet)
  const yellowAi = useYellowAi({
    shouldRender: !isMobileView || !isPartnerView,
  })

  const unchangedDataRef = useRef<{ globalArgs: any; geoLocation: GeoLocation }>({
    globalArgs,
    geoLocation,
  })

  const unchangedData = useMemo(() => {
    if (Object.keys(globalArgs || {}).length) unchangedDataRef.current.globalArgs = globalArgs
    if (Object.keys(geoLocation || {}).length) unchangedDataRef.current.geoLocation = geoLocation

    return unchangedDataRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const client = useApollo({ geoLocation: unchangedDataRef.current?.geoLocation })

  const [modalOptions, setModalOptions] = useState<ModalOptions>({
    isActive: false,
    content: null,
    withCloseBtn: false,
    closeOnOverlayClick: true,
    closeOnEsc: true,
  })
  useEventSessionAndRef()

  // workaround for fixing the css style sheet removal of dynamically imported components
  // more details here: https://github.com/vercel/next.js/issues/17464
  // if any style conflict issue found because of this,
  // remove this useEffect and import searchAutocomplete component without dynamic import
  useEffect(() => {
    const elements = document.querySelectorAll('head link[rel=stylesheet]')
    if (elements) {
      for (const el of elements as any) {
        if (el?.hasAttribute('data-n-p')) {
          el?.removeAttribute('data-n-p')
        }
      }
    }
  }, [])

  useEffect(() => {
    const { phoneQueryList, tabletQueryList } = getQueryLists()
    const isMobileView = phoneQueryList.matches
    const isTabletView = tabletQueryList.matches
    setIsMobileView(isMobileView)
    setIsTabletView(isTabletView)

    const phoneQueryHandler = (queryList: MediaQueryListEvent) => {
      const isMobileView = queryList.matches
      setIsMobileView(isMobileView)
    }
    const tabletQueryHandler = (queryList: MediaQueryListEvent) => {
      const isTabletView = queryList.matches
      setIsTabletView(isTabletView)
    }

    try {
      phoneQueryList.addEventListener('change', phoneQueryHandler)
      tabletQueryList.addEventListener('change', tabletQueryHandler)
    } catch {
      // required for Safari v13.1.3
      phoneQueryList.addListener(phoneQueryHandler)
      tabletQueryList.addListener(tabletQueryHandler)
    }

    return () => {
      try {
        phoneQueryList.removeEventListener('change', phoneQueryHandler)
        tabletQueryList.removeEventListener('change', tabletQueryHandler)
      } catch {
        // required for Safari v13.1.3
        phoneQueryList.removeListener(phoneQueryHandler)
        tabletQueryList.removeListener(tabletQueryHandler)
      }
    }
  }, [])

  // sift page change event
  useEffect(() => {
    const handleRouteChange = (_url: string, { shallow }: { shallow: 'boolean' }) => {
      if (shallow) return

      sift.events.pageView()
    }
    router.events.on('routeChangeStart', handleRouteChange)

    return () => router.events.off('routeChangeStart', handleRouteChange)
  }, [router.events])

  useEffect(() => {
    // This prevent page jumping when navigating back via browser back button
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
  }, [])

  const enableGtm = useMemo(() => {
    const { utm_source } = router.query
    // disable GTM for automation as crashing 3rd party scripts in GTM causing tests to fail
    // crashing blocks the next possible UI elements to be render or interact
    return utm_source !== 'test_automation'
    // run this only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { currency: currencyToSet } = useCurrencyForIsrPage({ serverCurrency: currentCurrency })

  const openModal = (options: ModalOptions) => setModalOptions({ ...options, isActive: true })

  const closeModal = useCallback(() => setModalOptions({ ...modalOptions, isActive: false }), [modalOptions])

  const globalContext = useMemo<IGlobalContext>(() => {
    return {
      ...unchangedData,
      currentCurrency: currencyToSet,
      campaignData,
      openModal,
      closeModal,
      isMobileView,
      isTabletView,
      yellowAi,
      // When an existing user logs in, the application performs a router.replace using the user's locale from their profile.
      // This action resets the app state, causing global arguments to become undefined, which briefly shows an error state.
      // To prevent this, initialize global arguments with initialGlobalArgs before the page refresh triggered by window.reload.
      globalArgs: unchangedData.globalArgs || initialGlobalArgs,
    }
  }, [closeModal, currencyToSet, isMobileView, isTabletView, yellowAi, unchangedData, campaignData])

  // Use the layout defined at the page level, if available
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getLayout = Component.getLayout ?? ((page: ReactNode, layoutProps?: LayoutProps) => page)
  const layoutProps = { isMobileView }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/manifest.json" />
      </Head>

      <ApolloProvider client={client}>
        <AuthProvider ssrIsLoggedIn={ssrIsLoggedIn}>
          <GlobalContextProvider value={globalContext}>
            <AppDataProvider ssrPartnerSessionInfo={ssrPartnerSessionInfo}>
              <WishlistProvider>
                <HeaderDataProvider>
                  <FingerprintContextProvider>
                    {enableGtm && (
                      <Script id="gtm-script" strategy="afterInteractive">
                        {`
                  (function (w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                    var f = d.getElementsByTagName(s)[0],
                      j = d.createElement(s),
                      dl = l != "dataLayer" ? "&l=" + l : "";
                    j.async = true;
                    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                    var n = d.querySelector("[nonce]");
                    n && j.setAttribute("nonce", n.nonce || n.getAttribute("nonce"));
                    f.parentNode.insertBefore(j, f);
                  })(window, document, "script", "dataLayer", "${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY}");
                `}
                      </Script>
                    )}
                    <Script
                      src={process.env.NEXT_PUBLIC_SENTRY_MIN}
                      strategy="afterInteractive"
                      crossOrigin="anonymous"
                      data-lazy="no"
                      onLoad={() => {
                        const sentryAdditionalParams: any = {}
                        const isProdEnv = process.env.NEXT_PUBLIC_APP_ENV === 'prod'

                        // send 2% trace on Prod and 100% trace on non-prod
                        sentryAdditionalParams.tracesSampleRate = isProdEnv ? 0.02 : 1.0
                        sentryAdditionalParams.tracePropagationTargets = isProdEnv
                          ? [/^https:\/\/traveller-core.pelago\.co\/graphql/]
                          : [
                              'localhost',
                              /^https:\/\/traveller-core.dev.pelago\.co\/graphql/,
                              /^https:\/\/traveller-core.stage.pelago\.co\/graphql/,
                            ]

                        window?.Sentry?.init({
                          ...sentryAdditionalParams,
                          release: `${publicRuntimeConfig?.appName}@${publicRuntimeConfig?.appVersion}`,
                          environment: process.env.NEXT_PUBLIC_APP_ENV,
                          hideSourceMaps: process.env.NODE_ENV === 'production',
                          widenClientFileUpload: true,
                          beforeSend: (event: any, hint: any) => {
                            const errorMsg = hint?.originalException?.message

                            if (errorMsg && SENTRY_ERROR_IGNORE_REGEX.some((regex) => regex.test(errorMsg))) {
                              return null
                            }
                            return event
                          },
                        })
                      }}
                    />
                    <Script
                      src="https://cdn.sift.com/s.js"
                      strategy="afterInteractive"
                      onLoad={() => {
                        const _user_id = ''
                        const _session_id = getBrowserCookie(COOKIES_DS_SESSION_ID) // Set to a unique session ID for the visitor's current browsing session.

                        const _sift = (window._sift = window._sift || [])
                        _sift.push(['_setAccount', process.env.NEXT_PUBLIC_SIFT_BEACON])
                        _sift.push(['_setUserId', _user_id])
                        _sift.push(['_setSessionId', _session_id])
                        _sift.push(['_trackPageview'])
                      }}
                    />
                    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
                      <ProgressBar />
                      <DestinationByRegionProvider>
                        {getLayout(<Component {...pageProps} />, layoutProps)}
                      </DestinationByRegionProvider>
                      <AuthModal />
                      <MarketingConsentModal />
                      <AccountCreatedToast />
                      <ModalOverlay {...modalOptions} onClose={closeModal} />
                      <ToastContainer />
                      <WishlistModal />
                      {!isPartnerView && <GoogleOneTap />}
                      <ExitIntentEvents />
                      {process.env.NODE_ENV === 'production' && !isPartnerView && <YellowChatWidget />}
                    </ErrorBoundary>
                  </FingerprintContextProvider>
                </HeaderDataProvider>
              </WishlistProvider>
            </AppDataProvider>
          </GlobalContextProvider>
        </AuthProvider>
      </ApolloProvider>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const {
    utm_campaign: utmCampaign = '',
    utm_source: utmSource = '',
    partnerId: partnerIdQueryParam,
    sessionId: sessionIdQueryParam,
  } = appContext.router.query

  // Initially get it from Query Params if available
  let partnerSessionInfo: any = {
    partnerId: partnerIdQueryParam,
    sessionId: sessionIdQueryParam,
  }

  const hasCampaign = utmCampaign?.length > 0 || utmSource?.length > 0

  const { cookie: reqHeaderCookies } = appContext.ctx.req?.headers || {}
  const reqCookies = cookie.parse(reqHeaderCookies || '')

  const apolloClient = initializeApollo(appContext.ctx)

  const appProps = await App.getInitialProps(appContext)

  const ssrIsLoggedIn = !!reqCookies[COOKIES_TOKEN]

  const userAgent = appContext.ctx.req?.headers?.[HEADER_USER_AGENT] || ''

  // using this for initial rendering. later isMobile will be set via useffect from client side
  let ssrIsMobile = Boolean(userAgent.match(IS_MOBILE_REGEX))

  let ssrIsTablet = Boolean(userAgent.match(IS_TABLET_REGEX))

  if (!ssrIsMobile && !ssrIsTablet) {
    ssrIsMobile = appContext.ctx.req?.headers?.[HEADER_CLOUNDFRONT_IS_MOBILE_VIEWER] === 'true'
    ssrIsTablet = appContext.ctx.req?.headers?.[HEADER_CLOUNDFRONT_IS_TABLET_VIEWER] === 'true'
  }

  try {
    let partnerSessionFromCookie

    // Read from cookie if not available in query params
    if (!partnerSessionInfo?.partnerId || !partnerSessionInfo?.sessionId) {
      partnerSessionFromCookie = reqCookies[COOKIES_PARTNER_SESSION_ID]
        ? JSON.parse(reqCookies[COOKIES_PARTNER_SESSION_ID])
        : undefined

      // Set Partner Session Info only if it is from the list of partners
      if (PARTNER_LIST.includes(partnerSessionFromCookie?.partnerId)) {
        partnerSessionInfo = {
          partnerId: partnerSessionFromCookie?.partnerId,
          sessionId: partnerSessionFromCookie?.sessionId,
        }
      }
    }

    if (partnerSessionFromCookie?.expires < Date.now()) {
      partnerSessionInfo = undefined
    }
  } catch {
    // Do nothing
  }

  // Determine if the user is viewing the site from a partner platform
  const hasPartnerSessionInfo = !!(partnerSessionInfo?.partnerId && partnerSessionInfo?.sessionId)

  let currentCurrency = hasPartnerSessionInfo ? PARTNER_DEFAULT_CURRENCY : reqCookies[COOKIES_CURRENCY]

  // do not have to query for global args from client side as the data is already loaded on the initial load
  // appContext.ctx.req?.url?.startsWith('/_next/data') => all the getServerSideProps request from client side
  if (appContext.ctx.req?.url?.startsWith('/_next/data') || isBrowser) {
    return {
      ...appProps,
      ssrIsMobile,
      ssrIsTablet,
      ssrIsLoggedIn,
      currentCurrency,
      ssrPartnerSessionInfo: partnerSessionInfo,
    }
  }

  // static pages doesnt use global args data and the page will be cached. So do not have to call the query during build time
  const isStaticRoute = STATIC_ROUTES.includes(appContext.ctx.pathname)

  const { data: response } = isStaticRoute
    ? await Promise.resolve({
        data: {
          campaign: {},
          globalArgs: {},
        },
      })
    : await apolloClient.query({
        query: GLOBAL_ARGS_QUERY_AND_CAMPAIGN,
        variables: { campaignId: utmCampaign, campaignSource: utmSource, hasCampaign },
      })

  const globalData: any = {}
  globalData.globalArgs = response.globalArgs?.output || {}
  const campaignData = response.campaign || {}

  const headers = appContext.ctx.req?.headers

  const latitude = headers?.[HEADER_CLOUDFRONT_LATITUDE] as string
  const longitude = headers?.[HEADER_CLOUDFRONT_LONGITUDE] as string
  const countryCode = headers?.[HEADER_CLOUDFRONT_COUNTRY] as string
  const cityName = headers?.[HEADER_CLOUDFRONT_CITY] as string

  globalData.geoLocation = {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
    countryCode,
    cityName,
  }

  // handle user request without any required cookies
  const cookiesToSet = []
  if (!reqCookies[COOKIES_CURRENCY]) {
    const countryToCurrencyData = await getCountryToCurrency()

    const { currencies = [] }: GlobalArgs = globalData.globalArgs

    const countryBasedCurrency =
      countryToCurrencyData[hasPartnerSessionInfo ? PARTNER_DEFAULT_COUNTRY_CODE : countryCode] || ''

    currentCurrency =
      currencies.find((item: Currency) => item.currency_id === countryBasedCurrency)?.currency_id ||
      FALLBACK_CURRENCY
    cookiesToSet.push(serialiseCookie(COOKIES_CURRENCY, currentCurrency))
  } else if (hasPartnerSessionInfo) {
    currentCurrency = PARTNER_DEFAULT_CURRENCY
    cookiesToSet.push(serialiseCookie(COOKIES_CURRENCY, currentCurrency))
  }

  // reponse cookies coming from _middleware
  const responseCookies = appContext.ctx.res?.getHeader('Set-Cookie')
  let responseDsUserId
  if (Array.isArray(responseCookies)) {
    responseDsUserId = responseCookies.find((item) => !!cookie.parse(item)[COOKIES_DS_USER_ID])
  } else if (typeof responseCookies === 'string' && !!cookie.parse(responseCookies)[COOKIES_DS_USER_ID]) {
    responseDsUserId = responseCookies
  }

  if (!reqCookies[COOKIES_DS_USER_ID] || responseDsUserId) {
    const _dsUserId = !responseDsUserId ? uuid() : ''
    const serialisedDsUserId = responseDsUserId || serialiseCookie(COOKIES_DS_USER_ID, _dsUserId)
    cookiesToSet.push(serialisedDsUserId)
  }

  const referer = headers?.[HEADER_REFERER]
  if (referer) cookiesToSet.push(serialiseCookie(REFERER_STORAGE_KEY, referer, REFERER_COOKIE_EXPIRATION))

  const utmParams = getStringifiedUtmParams(appContext.ctx.query)

  if (utmParams) {
    cookiesToSet.push(serialiseCookie(UTM_STORAGE_KEY, utmParams, UTM_COOKIE_EXPIRATION))
    cookiesToSet.push(serialiseCookie(COOKIES_LAST_30_CLICK, utmParams, COOKIES_LAST_30_CLICK_EXPIRATION))
  }

  // Set cookies only when query params has partner info
  if (hasPartnerSessionInfo && partnerIdQueryParam && sessionIdQueryParam) {
    cookiesToSet.push(
      serialiseCookie(
        COOKIES_PARTNER_SESSION_ID,
        JSON.stringify({
          partnerId: partnerSessionInfo?.partnerId,
          sessionId: partnerSessionInfo?.sessionId,
          expires: Date.now() + PARTNER_SESSION_EXPIRY * 1000,
        })
      )
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (!referer || referer?.indexOf(process.env.NEXT_PUBLIC_HOST!) === -1) {
    cookiesToSet.push(serialiseCookie(COOKIES_LAST_CLICK, utmParams, COOKIES_LAST_CLICK_EXPIRATION))
  }

  if (!reqCookies[COOKIES_FIRST_CLICK]) {
    const defaultValue = ' ' // string with empty space to avoid false condition next time when check above condition as this is one(first) time set only
    cookiesToSet.push(
      serialiseCookie(COOKIES_FIRST_CLICK, utmParams || defaultValue, COOKIES_FIRST_CLICK_EXPIRATION)
    )
  }

  // set these cookies in client side
  // this will be used by apollo client later too
  cookiesToSet.length && appContext.ctx.res?.setHeader('Set-Cookie', cookiesToSet)

  return {
    ...globalData,
    ...appProps,
    campaignData,
    ssrIsTablet,
    ssrIsMobile,
    ssrIsLoggedIn,
    currentCurrency,
    ssrPartnerSessionInfo: partnerSessionInfo,
  }
}

// @ts-ignore
export default appWithTranslation(MyApp)
