import React, { MouseEventHandler, forwardRef } from 'react'
import cn from 'classnames'

import { Icon, IconNames, Sizes } from 'ui/icon'

import { Link } from 'components/link'

import s from './styles.module.scss'

export type Size = 'xsmall' | 'small' | 'medium' | 'large'

export interface ChipProps {
  id?: string
  label: string
  selected?: boolean
  truncateLongText?: boolean
  className?: string
  iconName?: IconNames
  iconSize?: Sizes | number
  variant?: 'light' | 'light-ghost' | 'light-ghost-bold' | 'dark' | 'dark-ghost' | 'dark-ghost-bold'
  size?: Size
  asLink?: string
  onClick?: (arg: string) => void
  onDelete?: (arg: string) => void
}

const Chip = forwardRef<any, ChipProps>(
  (
    {
      id,
      label,
      selected,
      className,
      iconName,
      iconSize = 'small',
      onClick,
      onDelete,
      size = 'small',
      variant = 'light',
      asLink,
      truncateLongText = false,
    },
    ref
  ) => {
    const commonProps = {
      key: id || label,
      className: cn(s.chip, s[variant], className, s[`chip-${size}`], {
        [s.selected]: selected,
      }),
      ref,
    }
    const children = (
      <>
        {iconName && (
          <Icon
            name={iconName}
            size={iconSize}
            className={cn(`mr-${variant === 'dark-ghost-bold' || variant === 'light-ghost-bold' ? 2 : 1}`)}
          />
        )}
        {label}
        {selected && onDelete && (
          <Icon
            name="delete-x"
            size={size === 'small' ? 'mini' : 'small'}
            className={s.delete}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onDelete?.(id || label)
            }}
          />
        )}
      </>
    )

    const handleClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (e) => {
      if (!asLink) e.preventDefault()
      onClick?.(id || label)
    }

    if (!!asLink) {
      return (
        <Link {...commonProps} href={asLink} passHref>
          <a className={commonProps.className} onClick={handleClick}>
            {truncateLongText ? <span className={s.truncateLongText}>{children}</span> : children}
          </a>
        </Link>
      )
    }
    return (
      <button {...commonProps} onClick={handleClick}>
        {truncateLongText ? <span className={s.truncateLongText}>{children}</span> : children}
      </button>
    )
  }
)

Chip.displayName = 'Chip'

export { Chip }
