import { ReactNode, forwardRef } from 'react'
import cn from 'classnames'

import { LayoutButton as Button } from '../button'
import { LayoutButtonProps } from '../button/types'

import s from './styles.module.scss'

interface DropdownButtonProps extends LayoutButtonProps {
  dropdown: ReactNode
  dropdownButton?: ReactNode
  direction?: 'top' | 'bottom'
  className?: string
  dropdownContainerClassName?: string
  dropDownClassName?: string
}

const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (
    {
      dropdown,
      dropdownButton,
      direction = 'bottom',
      className,
      dropdownContainerClassName,
      dropDownClassName,
      ...buttonProps
    },
    ref
  ) => {
    return (
      <div className={cn(s.dropdown, className)}>
        {dropdownButton || (
          <Button
            {...buttonProps}
            className={cn(s.dropdownTrigger, { [s.avatar]: buttonProps.variant === 'avatar' })}
            ref={ref}
          ></Button>
        )}
        <div className={cn(s.dropdownMenu, [s[`direction-${direction}`]], dropdownContainerClassName)}>
          <div className={cn(s.dropdownContent, dropDownClassName)}>{dropdown}</div>
        </div>
      </div>
    )
  }
)

DropdownButton.displayName = 'DropdownButton'

export { DropdownButton }
