import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { getCookieCurrency } from 'lib/utils'
import { isIsrPageRequest } from 'lib/utils/middleware'

// For ISR page currency will be cached currency hence we need to take care about user's preference currency
// instead of cached currency
function useCurrencyForIsrPage({ serverCurrency }: { serverCurrency: string | undefined }) {
  const router = useRouter()

  const currencyForIsrPage = useMemo(() => {
    const isIsrPage = isIsrPageRequest(router.asPath)
    if (!isIsrPage) return serverCurrency

    const currencyCookie = getCookieCurrency()
    // For ISR page server currency will be cached cookie so we take the user browser currency instead of server side
    if (currencyCookie && serverCurrency !== currencyCookie) {
      return currencyCookie
    }
    return serverCurrency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, serverCurrency])

  return { currency: currencyForIsrPage }
}

export { useCurrencyForIsrPage }
