import React, { useMemo } from 'react'
import cn from 'classnames'

import { Icon, Sizes as IconSizes } from 'ui/icon'

import { ButtonProps, Sizes } from './types'

import s from './styles.module.scss'

type ComponentProps = ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>

const btnSizeToIconSize: Record<Sizes, IconSizes> = {
  small: 'small',
  medium: 'semi-medium',
  large: 'medium',
}

const iconBtnToIconSize: Record<Sizes, IconSizes> = {
  small: 'micro',
  medium: 'mini',
  large: 'small',
}

const Button = React.forwardRef<HTMLButtonElement, ComponentProps>(
  (
    {
      shape,
      disabled,
      fluid,
      children,
      iconName,
      iconPosition = 'left',
      size = 'medium',
      variant = 'primary',
      loading,
      className,
      onClick,
      ...rest
    },
    ref
  ) => {
    const isIconButton = !!iconName && !children
    const classes = cn(
      s.btn,
      s[`btn-${variant}`],
      s[`btn-${size}`],
      {
        [s[`icon-${iconPosition}`]]: !!iconName,
        [s['btn-fluid']]: fluid,
        [s['btn-rounded']]: shape === 'round',
        [s['btn-circular']]: shape === 'circle',
        [s['btn-outline']]: variant.includes('outline'),
        [s['loading']]: loading,
        [s['is-icon-btn']]: isIconButton,
        [s['has-icon']]: !!iconName,
      },
      className
    )

    const buttonIconComponent = useMemo(() => {
      if (!iconName) return null
      const iconSize = isIconButton ? iconBtnToIconSize[size] : btnSizeToIconSize[size]

      if (!iconSize) throw new Error('Icon size mapping is missing')

      return React.createElement(Icon, { name: iconName, size: iconSize })
    }, [iconName, size, isIconButton])

    return (
      <button ref={ref} {...rest} className={classes} disabled={disabled || loading} onClick={onClick}>
        {iconPosition === 'left' && buttonIconComponent}
        {children}
        {iconPosition === 'right' && buttonIconComponent}
        {loading && (
          <>
            <div></div>
            <div></div>
            <div></div>
          </>
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'

export { Button }
