import { gql } from '@apollo/client'

import { PelagoError, productCardFields } from 'gql/fragments'

export const ALL_DESTINATIONS_QUERY = {
  query: gql`
    query allDestinations($pageSize: Int, $countryId: String) {
      allDestinations(pageSize: $pageSize, countryId: $countryId) {
        ... on Destinations {
          destinations {
            destinationId
            destinationName
            destinationUri
            destinationRank
            destinationMetaData
            countryId
            mediaData
            description
          }
          destinationCount
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'allDestinations',
}

export const DESTINATION_CARDS_QUERY = {
  query: gql`
    query destinationCards($pageSize: Int) {
      destinationCards(pageSize: $pageSize) {
        ...on DestinationCards {
          destinations {
            destinationId
            name
            thumbnail
            uri
            rank
            code
          }
          destinationCount
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'destinationCards',
}

export const ARTICLE_LIST = {
  query: gql`
    query dataCardComponent(
      $page: PageTypeEnum!,
      $componentId: String!,
      $pageSize: Int,
      $pageNumber: Int
    ) {
      dataCardComponent(
        page: $page,
        componentId: $componentId,
        pageSize: $pageSize,
        pageNumber: $pageNumber
      ) {
        ...on DataCardComponent {
          heading
          subHeading
          cardsCount
          cards {
            cardId
            thumbnail
            header
            redirectUri
          } 
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'dataCardComponent',
}

export const DATA_CARD_LIST = {
  query: gql`
    query dataCardComponent(
      $componentId: String!,
      $page: PageTypeEnum!
    ) {
      dataCardComponent (
        componentId: $componentId,
        page: $page
      ) {
        ... on DataCardComponent {
          heading
          subHeading
          cardsCount
          cards {
            thumbnail
            header
            redirectUri
            cardId
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'dataCardComponent',
}

export const DESTINATION_QUERY = {
  query: gql`
    query destination($destinationId: String!) {
      destination(destinationId: $destinationId) {
        ...on Destination {
          destinationName
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'destination',
}
const PRODUCT_FILTER_QUERY_BODY = `
  searchFilterProducts(
    searchInput: $searchInput
  ) {
    ...on ESFilterProducts {
      title
      products {
        ${productCardFields}
        searchScore
        searchMeta
      }
      productCount
      sortBy
      minPrice
      maxPrice
      currency
      categoryCount
      destinationIds
      countryIds
      searchText
    }
    ${PelagoError}
  }
`

export const PRODUCT_FILTER_QUERY = {
  query: gql`
    query searchFilterProducts(
      $searchInput: SearchFilterProductInput!
    ) {
      ${PRODUCT_FILTER_QUERY_BODY}
    }
  `,
  queryName: 'searchFilterProducts',
}

const COMPONENT_FILTER_QUERY_BODY = `
  componentFilterProducts(
    searchInput: $searchInput
  ) {
    ...on ESFilterProducts {
      title
      products {
        ${productCardFields}
        searchScore
        searchMeta
      }
      productCount
      sortBy
      minPrice
      maxPrice
      currency
      categoryCount
      destinationIds
      countryIds
      searchText
    }
    ${PelagoError}
  }
`

export const COMPONENT_FILTER_PRODUCTS_QUERY = {
  query: gql`
    query componentFilterProducts($searchInput: ComponentFilterProductsInput!) {
      ${COMPONENT_FILTER_QUERY_BODY}
    }
  `,
  queryName: 'componentFilterProducts',
}

export const TAGS_CATEGORIES_COLLECTION_QUERY = {
  query: gql`
    query tagsCategoriesCollection(
      $destinationId: String,
      $destinationIds: [String]
      $canCallCollections: Boolean!
    ) {
      destinationCategories(destinationId: $destinationId, destinationIds: $destinationIds) {
        ...on CategoryTree {
          tree
        }
        ${PelagoError}
      }
      destinationTagAssets(destinationIds: $destinationIds) {
        ... on Tag {
          tags
        }
        ${PelagoError}
      }
      destinationCollections(destinationIds: $destinationIds) @include(if: $canCallCollections) {
        ... on PelagoOutput {
            output
        }
        ${PelagoError}
      }
    }
  `,
  queryName: {
    categories: 'destinationCategories',
    tags: 'destinationTagAssets',
    collections: 'destinationCollections',
  },
}

export const DS_PRODUCTS = {
  query: gql`
    query dsProducts(
      $apiType: String!, 
      $payload: GenericScalar!, 
      $filters: GenericScalar
    ) {
      dsProducts(
        apiType: $apiType, 
        payload: $payload, 
        filters: $filters
      ) {
        ... on ESProductCards {
          productCount
          products {
            ${productCardFields}
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'dsProducts',
}

export const PRODUCT_FILTERS_BODY = `
  productFilters {
    ...on ProductFilters {
      categories
      tags
      destinations
      others
    }
    ${PelagoError}
  }
`

export const DESTINATION_TAGS_CATEGORIES_QUERY = {
  query: gql`
    query destination($destinationId: String!) {
      destination(destinationId: $destinationId) {
        ...on Destination {
          destinationName
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'destination',
}

export const COLLECTION_DESTINATIONS_QUERY = {
  query: gql`
    query collectionDestinationCards($collectionId: String!, $pageSize: Int) {
      collectionDestinationCards(collectionId: $collectionId, pageSize: $pageSize) {
        ...on DestinationCards {
          destinationCount
          destinations {
            destinationId
            uri
            name
            thumbnail
            rank
            code
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'collectionDestinationCards',
}

export const NEAR_BY_COLLECTIONS_QUERY = {
  query: gql`
    query collections($collectionIds: [String], $pageSize: Int) {
      collections(collectionIds: $collectionIds, pageSize: $pageSize) {
        ...on CollectionCards {
          collectionCount
          collections {
            collectionId
            collectionUri
            collectionCode
            collectionLabel
            collectionMediaData
            collectionType
            collectionDescription
            destinationIds
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'collections',
}

export const DESTINATION_LIST_BASED_ON_REGION_QUERY = {
  query: gql`
    query destinationListBasedOnRegion($pageSize: Int!, $page: Int!) {
      destinationListBasedOnRegion(pageSize: $pageSize, page: $page) {
        ... on DestinationListByRegionOutput {
          topTrendingDestinations {
            destinationCount
            destinations {
              destinationId
              uri
              name
              thumbnail
              rank
              code
              countryId
              countryName
              regionId
            }
          }
          groupedByRegion {
            region {
              regionId
              regionCode
              regionName
              continentCode
            }
            destinationList {
              destinationCount
              destinations {
                destinationId
                uri
                name
                code
                thumbnail
                rank
                countryId
                countryName
                regionId
              }
            }
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'destinationListBasedOnRegion',
}
