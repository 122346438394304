import cn from 'classnames'

import { Icon } from 'ui/icon'

import { ActiveLink } from 'components/active-link'
import { useLangAndCurrency } from 'components/lang-currency/hooks'
import { WishlistDropdown } from 'components/wishlist-dropdown'
import { LangCurrencyDrawer } from 'components/lang-currency/drawer'
import Settings from 'components/settings'
import { ExternalLink } from 'components/external-link'
import { AuthenticateCtaSection } from 'components/authenticate-cta-section'

import useTranslation from 'lib/hooks/useTranslation'
import useAuth from 'lib/hooks/useAuth'

import { buildPath } from 'lib/utils'
import { useGlobalContext } from 'lib/context/global-context'
import { useWishlistData } from 'lib/context/wishlist-context'

import {
  ACCOUNT_BOOKINGS_ROUTE,
  ACCOUNT_PROFILE_ROUTE,
  ACCOUNT_REVIEWS_ROUTE,
  ACCOUNT_REWARDS_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  WISHLIST_ROUTE,
} from 'lib/constants/routes'
import { HELPCENTER_LINK } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import s from './styles.module.scss'

const MobileMenu = ({
  wishlistActive,
  menuActive,
  hasLangCurrSelector,
  setwishlistActive,
  trackEvent,
  currentLocale = '',
  toggleMenu,
  destinationMenuActive,
  handleTrackEventCallback,
  avatarText,
  setDestinationMenuActive,
}: any) => {
  const { currentCurrency, isMobileView, yellowAi } = useGlobalContext()
  const { logout, user, isLoggedIn } = useAuth()
  const { t, sp } = useTranslation('common')
  const wishlist = useWishlistData()

  const {
    showLanguageSelector,
    currencyOptions,
    currencyDrawerActive,
    handleCurrencySwitch,
    openCurrencyDrawer,
    closeCurrencyDrawer,
    languageOptions,
    langDrawerActive,
    handleLanguageSwitch,
    openLangDrawer,
    closeLangDrawer,
    langCurrencyState,
  } = useLangAndCurrency({
    trackEvent: (args) => trackEvent.current(args),
    currCurrencyId: currentCurrency || '',
    currLocaleId: currentLocale || '',
  })

  const handleMobileMenuClick = () => {
    if (currencyDrawerActive) closeCurrencyDrawer()
    if (langDrawerActive) closeLangDrawer()
  }

  return (
    <>
      {!isLoggedIn && wishlistActive && <WishlistDropdown onCloseWishlist={() => setwishlistActive(false)} />}

      <nav className={cn(s.mobileMenu, { [s.active]: menuActive })}>
        <LangCurrencyDrawer
          showLanguageSelector={showLanguageSelector}
          noHeaderOffset
          // currency
          selectedCurrencyId={langCurrencyState.currencyId}
          currencyOptions={currencyOptions}
          currencyDrawerActive={hasLangCurrSelector && currencyDrawerActive}
          onCurrencyDrawerClose={closeCurrencyDrawer}
          onCurrencySwitch={handleCurrencySwitch}
          //language
          selectedLangId={langCurrencyState.langId}
          languageOptions={languageOptions}
          langDrawerActive={hasLangCurrSelector && langDrawerActive}
          onLangDrawerClose={closeLangDrawer}
          onLanguageSwitch={handleLanguageSwitch}
        />

        <div
          className={cn(s.mobileMenuItems, {
            [s.menuActive]: currencyDrawerActive || langDrawerActive || destinationMenuActive,
          })}
          onClick={handleMobileMenuClick}
        >
          {isMobileView && !isLoggedIn && <AuthenticateCtaSection onClick={toggleMenu} />}

          <Settings
            wrapperClassName={cn(s.dropdownContent, { [s.withSignupBanner]: !isLoggedIn })}
            onItemClicked={toggleMenu}
            trackEvent={handleTrackEventCallback}
            custom
          >
            {/* [Logged in users] Menu items for  */}
            {isLoggedIn && (
              <>
                <Settings.Item onClick={logout} isHeader>
                  <div>
                    <span className={s.avatar}>{avatarText}</span>
                    <span className={cn(s.userName, 'ml-4')}>
                      {user?.firstName}
                      {sp}
                      {user?.lastName}
                    </span>
                  </div>
                </Settings.Item>
                <ActiveLink href={buildPath(WISHLIST_ROUTE)} activeClassName="active">
                  <a
                    className={cn({ [s.hasItems]: wishlist?.count > 0 })}
                    onClick={() => {
                      trackEvent.current?.({
                        attributeId: EVENTS.HEADER_WISHLIST,
                        attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                      })
                      toggleMenu()
                    }}
                  >
                    <Icon name="heart" size="small" /> {t('t.wishlists', { ns: 'common' })}
                  </a>
                </ActiveLink>
                <ActiveLink key="bookings" href={buildPath(ACCOUNT_BOOKINGS_ROUTE)} activeClassName="active">
                  <a>
                    <Icon name="booking-date-outline" size="small" /> {t('bookings', { ns: 'booking' })}
                  </a>
                </ActiveLink>
                <ActiveLink key="rewards" href={buildPath(ACCOUNT_REWARDS_ROUTE)} activeClassName="active">
                  <a>
                    <Icon name="circle-wavy-percent" size="small" viewBox="0 0 24 24" />
                    {t('t.rewards', { ns: 'common' })}
                  </a>
                </ActiveLink>
                <ActiveLink key="reviews" href={buildPath(ACCOUNT_REVIEWS_ROUTE)} activeClassName="active">
                  <a>
                    <Icon name="chat-teardrop-text" size="small" />
                    {t('review', { ns: 'product', count: 2 })}
                  </a>
                </ActiveLink>
                <ActiveLink key="profile" href={buildPath(ACCOUNT_PROFILE_ROUTE)} activeClassName="active">
                  <a>
                    <Icon name="user" size="small" /> {t('profile', { ns: 'user' })}
                  </a>
                </ActiveLink>
                <ActiveLink key="settings" href={buildPath(ACCOUNT_SETTINGS_ROUTE)} activeClassName="active">
                  <a>
                    <Icon name="settings" size="small" /> {t('t.settings')}
                  </a>
                </ActiveLink>

                <Settings.Item
                  key="logout"
                  onClick={() => {
                    logout()
                    toggleMenu()
                  }}
                >
                  <span>
                    <Icon name="sign-out" size="small" /> {t('t.logOut')}
                  </span>
                </Settings.Item>
                <Settings.Item isDivider />
              </>
            )}

            {/* [Logged in/out users] Common Menu items */}
            <Settings.Item key="explore" isHeader>
              {t('t.explore', { ns: 'common' })}
            </Settings.Item>

            <Settings.Item key="destination" onClick={() => setDestinationMenuActive((curr: any) => !curr)}>
              <span>
                <Icon name="map-pin" size="small" />
                {t('t.ourDestinations', { count: 0, ns: 'common' })}
              </span>
            </Settings.Item>

            {hasLangCurrSelector && (
              <>
                <Settings.Item isDivider />
                <Settings.Item key="globalPreferences" isHeader>
                  {t('globalPreferences', { ns: 'user' })}
                </Settings.Item>
                <Settings.Item key="currency" onClick={openCurrencyDrawer}>
                  <span>
                    <Icon name="currency-converter" size="small" />
                    <span className={cn(s.currency, 'mr-2')}>
                      <span>{t('t.currency')}</span>
                      <span className={s.value}>
                        <span>{langCurrencyState.currencyId}</span>
                      </span>
                    </span>
                  </span>
                </Settings.Item>
                {showLanguageSelector && (
                  <Settings.Item key="language" onClick={openLangDrawer}>
                    <span>
                      <Icon name="globe" size="small" />
                      <span className={cn(s.language, 'mr-2')}>
                        <span>{t('t.language')}</span>
                        <span className={s.value}>
                          <span>{langCurrencyState.langLabel}</span>
                        </span>
                      </span>
                    </span>
                  </Settings.Item>
                )}
              </>
            )}

            <Settings.Item isDivider />

            <Settings.Item key="support" isHeader>
              {t('t.support', { ns: 'common' })}
            </Settings.Item>

            <div
              key="virtual-assistant"
              onClick={() => {
                yellowAi.showAndToggle()

                trackEvent.current?.({
                  attributeId: EVENTS.HEADER,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.CHATBOT,
                })
              }}
            >
              <Icon name="chat-smile" size="small" /> {t('t.virtualAssistant', { ns: 'common' })}
            </div>

            <ExternalLink
              key="help"
              href={HELPCENTER_LINK}
              onClick={() => {
                trackEvent.current?.({
                  link: HELPCENTER_LINK,
                  attributeId: EVENTS.HEADER_HELP,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                })
                toggleMenu()
              }}
            >
              <Icon name="information-outline" size="small" />
              {t('t.helpCenter', { ns: 'common' })}
            </ExternalLink>
          </Settings>
        </div>
      </nav>
    </>
  )
}

export default MobileMenu
