import { gql } from '@apollo/client'
import { PelagoError } from './fragments'
import { PRODUCT_FILTERS_BODY } from './queries'
import { DYNAMIC_COMPONENTS_QUERY_BODY } from './queries/common'

export * from './queries'
export * from './mutations'
export * from './mapper'

export const GLOBAL_ARGS_AND_CAMPAIGN_QUERY_BODY = `
  globalArgs {
    ...on PelagoOutput {
      output
    }
    ${PelagoError}
  }
  campaign(campaignSource: $campaignSource, campaignId: $campaignId) @include(if: $hasCampaign) {
    ... on Campaign {
      campaignId
      campaignName
      startDate
      endDate
      isActive
      campaignMetaData
      mediaData
      dateModified
      content
    }
    ${PelagoError}
  }
`
export const GLOBAL_ARGS_QUERY_AND_CAMPAIGN = gql`
  query globalArgsAndCampaign($hasCampaign: Boolean!, $campaignId: String, $campaignSource: String) {
    ${GLOBAL_ARGS_AND_CAMPAIGN_QUERY_BODY}
  }
`

export const SEARCH_PAGE_QUERY = {
  query: gql`
    query globalArgsCampaignProductFiltersAndCollection($hasCampaign: Boolean!, $campaignId: String, $campaignSource: String, $page: String!, $destinationId: String, $countryId: String, $params: ComponentParams, $entityId: String, $entityType: ComponentEntityTypeEnum, $preview: Boolean, $context: DynamicComponentContext, $details: DynamicComponentInput) {
      ${GLOBAL_ARGS_AND_CAMPAIGN_QUERY_BODY}
      ${PRODUCT_FILTERS_BODY}
      ${DYNAMIC_COMPONENTS_QUERY_BODY}
    }
  `,
  queryName: {
    globalArgs: 'globalArgs',
    campaign: 'campaign',
    productFilters: 'productFilters',
    dynamicComponents: 'dynamicComponents',
  },
}

export const TRACK_CONTEXT_QUERY = gql`
  query trackContext {
    trackContext {
      ...on PelagoOutput {
        output
      }
      ${PelagoError}
    }
  }
`
