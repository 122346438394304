import { capitalize, getBrowserCookie, getUtmParams } from 'lib/utils'

import { COOKIES_DS_SESSION_ID, COOKIES_PARTNER_SESSION_ID } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

const getViewport = (() => {
  let cache: any = null
  return () => {
    if (!cache) {
      cache = {
        w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
      }
    }
    return cache
  }
})()

const idToName = (id: string) => {
  return id
    ?.split('_')
    ?.map((word) => capitalize(word))
    .join(' ')
}

export const pageViewed = ({ pageName, ...params }: any) => {
  const { w, h } = getViewport()
  const defaultParams = {
    pageName,
    eventType: EVENTS.TYPE.EXPOSURE,
    attributeType: EVENTS.ATTRIBUTES_TYPE.PAGE,
    language: navigator.language.split(';')[0],
    resolutionSize: `${w * window.devicePixelRatio}x${h * window.devicePixelRatio}`,
    viewPort: `${w}x${h}`,
    isPage: true,
  }

  track({ ...params, ...defaultParams })
}

export const track = ({
  eventType = EVENTS.TYPE.CLICK,
  isPage: isPageEvent,
  isInteractionEvent,
  ...params
}: any) => {
  const utmParams = getUtmParams()
  const partnerCookies = getBrowserCookie(COOKIES_PARTNER_SESSION_ID) || ''

  let updatedUtmParams
  if (utmParams && typeof utmParams === 'object') {
    updatedUtmParams = {
      ...utmParams,
      extended: {
        lastUtm: utmParams,
        lastClick: getUtmParams('lastClick'),
        firstClick: getUtmParams('firstClick'),
        last30Click: getUtmParams('last30Click'),
      },
    }
  }

  const defaultParams = {
    eventType,
    utmParams: updatedUtmParams,
    timestamp: Date.now(),
    platform: partnerCookies ? JSON.parse(partnerCookies)?.partnerId : 'web',
  }
  // exposure events are marked as noninteraction event by default
  // but certain cases where we want exposure as a interaction event, at that time use isInteractionEvent flag
  if (eventType === EVENTS.TYPE.EXPOSURE && !isInteractionEvent) {
    // @ts-ignore
    defaultParams.nonInteraction = 1
  }
  const attributeId = `${params.attributeId}_${params.attributeType}`.toLowerCase()
  const attributeName = `${isPageEvent ? '' : idToName(params.pageName)} ${idToName(
    params.attributeId
  )} ${idToName(params.attributeType)} ${capitalize(eventType)}`?.trim()
  const dsSessionId = getBrowserCookie(COOKIES_DS_SESSION_ID)
  const payload = { ...defaultParams, ...params, attributeId, attributeName, dsSessionId }

  // send track event data to redshift
  if (!process.env.NEXT_PUBLIC_ENABLE_DEV_TRACKING && process.env.NODE_ENV === 'development') return

  fetch(`${process.env.NEXT_PUBLIC_EVENT_TRACKING_URL}`, {
    method: 'POST',
    mode: 'no-cors',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
