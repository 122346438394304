import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import { Link } from 'components/link'
import { useLangAndCurrency } from 'components/lang-currency/hooks'
import { DestinationModal } from 'components/destination-modal'
import { Tooltip } from 'components/tooltip'

import useAuth from 'lib/hooks/useAuth'
import useTranslation from 'lib/hooks/useTranslation'
import { useWhiteLabel } from 'lib/hooks/useWhiteLabel'

import { useWishlistData } from 'lib/context/wishlist-context'
import { useHeaderData } from 'lib/context/header-data-context'
import { useGlobalContext } from 'lib/context/global-context'
import { useAppData } from 'lib/context/app-data-context'
import { buildPath } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'
import { ARTICLE_HOME_ROUTE, HOME_ROUTE } from 'lib/constants/routes'

import PelagoWhiteLogoDesktop from 'brand-assets/illustrations/logo/pelago-by-sg-airline-desktop-white-yellow.svg'
import PelagoWhiteLogoMobile from 'brand-assets/illustrations/logo/pelago-by-sg-airline-mobile-white-yellow.svg'
import PelagoBlogLogoDesktop from 'brand-assets/illustrations/logo/pelago-blog-yellow.svg'

import { DesktopLogo } from './desktop-logo'
import { DesktopMenu } from './desktop-menu'
import { Search } from './search'
import MobileMenu from './mobile-menu'
import HelpCenter from './help-center-menu'
import { PartnerLogo } from './partner-logo'

import s from './styles.module.scss'

interface HeaderProps {
  hasLangCurrSelector?: boolean
  hasSearch?: boolean
  isNonSticky?: boolean
  variant?: 'default' | 'static' | 'only-help-link' | 'helpcenter'
  additionalParams?: any
  notification?: Record<string, any>
  isBlogPage?: boolean
}

const Header: FC<HeaderProps> = ({
  hasLangCurrSelector = true,
  hasSearch = true,
  isNonSticky = false,
  variant = 'default',
  additionalParams,
  notification,
  isBlogPage,
}) => {
  const router = useRouter()
  const { user, isLoggedIn } = useAuth()
  const {
    trackEvent,
    partnerFeatureControl: { showFeature },
  } = useAppData()
  const hederContainerRef = useRef<HTMLDivElement | null>(null)
  const { currentCurrency, isMobileView } = useGlobalContext()
  const wishlist = useWishlistData()
  const {
    isTransparent: isTransparentHeader,
    hasSearch: isSearchInputVisible,
    setSearchVisibility,
  } = useHeaderData()

  // track event should be passed as fn for proper reference
  const {
    showLanguageSelector,
    currencyOptions,
    handleCurrencySwitch,
    closeCurrencyDrawer,
    languageOptions,
    handleLanguageSwitch,
    closeLangDrawer,
    langCurrencyState,
  } = useLangAndCurrency({
    trackEvent: (args) => trackEvent.current(args),
    currCurrencyId: currentCurrency || '',
    currLocaleId: router.locale || '',
  })
  const [isWhiteLabel, whiteLabelPartner] = useWhiteLabel()

  const [menuActive, setMenuActive] = useState(false)
  const [wishlistActive, setwishlistActive] = useState(false)
  const [destinationMenuActive, setDestinationMenuActive] = useState(false)

  const { t, tsmart } = useTranslation('common')

  const toggleMenu = () => {
    trackEvent.current?.({ attributeId: EVENTS.HEADER_MENU, attributeType: EVENTS.ATTRIBUTES_TYPE.TOGGLE })

    // Set the bottom px of the header
    if (hederContainerRef.current) {
      const { bottom } = hederContainerRef.current.getBoundingClientRect()
      document.body.style.setProperty('--header-bottom-position', bottom + 'px')
    }

    setMenuActive(!menuActive)
    if (!menuActive) {
      closeCurrencyDrawer()
      closeLangDrawer()
      setDestinationMenuActive(false)
    }
  }

  const onCloseMenu = useCallback(() => setMenuActive(false), [])

  const onCloseDestinationModal = useCallback(() => {
    setDestinationMenuActive(false)
  }, [])

  const handleTrackEventCallback = (params: any) => {
    trackEvent?.current(params)
  }

  useEffect(() => {
    setSearchVisibility(!!hasSearch)
  }, [hasSearch, setSearchVisibility])

  useEffect(() => {
    if (!menuActive) {
      closeCurrencyDrawer()
      closeLangDrawer()
      setDestinationMenuActive(false)
    }

    document.body.classList.toggle('overflow-hidden', (menuActive && isMobileView) || !!wishlist.showTooltip)
  }, [menuActive, isMobileView, closeCurrencyDrawer, closeLangDrawer, wishlist.showTooltip])

  const avatarText = useMemo(() => {
    if (!user?.firstName) return <Icon name="user" />

    return `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
  }, [user])

  const PelagoWhiteLogo = useMemo(() => {
    return isMobileView
      ? PelagoWhiteLogoMobile
      : isBlogPage || isWhiteLabel
      ? PelagoBlogLogoDesktop
      : PelagoWhiteLogoDesktop
  }, [isMobileView, isBlogPage, isWhiteLabel])

  if (variant === 'static' || variant === 'only-help-link' || variant === 'helpcenter') {
    return (
      <div
        className={cn(s.header, {
          [s.transparent]: isTransparentHeader && !menuActive && !wishlistActive,
          [s.nonSticky]: isNonSticky,
        })}
      >
        <div className={s.headerInner}>
          <div className={cn(s.headerNavLeft, s.fullWidth)}>
            <a
              className={cn(s.headerLogo, s.headerNavItem)}
              onClick={() => {
                trackEvent.current?.({
                  attributeId: EVENTS.HEADER_LOGO,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                })

                if (variant === 'static') {
                  // Need a full page refresh here to trigger the getInitialProps in _app page.
                  // Static pages doesnt call _app getInitialProps and this is required for getting the latest globalArgs.
                  // Since static page is generated during build time, globalArgs data shown here will be stale, so proceeding to hide all nav items which uses globalArgs data.
                  window.location.href = buildPath(HOME_ROUTE)
                } else {
                  router.push(buildPath(HOME_ROUTE))
                }
              }}
            >
              <PelagoWhiteLogo alt="Pelago Brand Logo" className={s['pelago-white-logo']} />
            </a>
            {variant === 'helpcenter' && (
              <p className={cn(s.headerTitle, 'label-lg')}>{tsmart('t.helpCenter', { ns: 'common' })}</p>
            )}
            {variant !== 'helpcenter' && isWhiteLabel && whiteLabelPartner && (
              <div className="ml-4 flex-center">
                <PartnerLogo
                  whiteLabelPartner={whiteLabelPartner}
                  isWhiteLabel={isWhiteLabel}
                  visible={isSearchInputVisible && !additionalParams?.searchInputAsIcon}
                />
              </div>
            )}
          </div>

          {variant === 'only-help-link' && <HelpCenter variant={variant} trackEvent={trackEvent} />}
        </div>
      </div>
    )
  }

  const showDesktopLogo = !isMobileView || !isSearchInputVisible || !!additionalParams?.searchInputAsIcon

  return (
    <>
      {notification}
      <div
        className={cn(s.header, {
          [s.transparent]: isTransparentHeader && !menuActive && !wishlistActive,
          [s.home]: additionalParams?.isHomePage,
          [s.nonSticky]: isNonSticky,
        })}
        ref={hederContainerRef}
      >
        <div className={s.headerInner}>
          {/* left column - pelago logo */}
          {showDesktopLogo && (
            <DesktopLogo isBlogPage={isBlogPage} hasPartnerLogo={isWhiteLabel} trackEvent={trackEvent} />
          )}

          {/* center column - others logo & search option */}
          <div
            className={cn(s.headerNavCenter, s.headerNavItem, s._noHover, {
              [s.hasSearch]: isSearchInputVisible,
              [s.isBlog]: isBlogPage || isWhiteLabel,
            })}
          >
            {isBlogPage && (
              <Link href={buildPath(ARTICLE_HOME_ROUTE)}>
                <a
                  onClick={() =>
                    trackEvent.current?.({
                      attributeId: EVENTS.HEADER_BLOG_LOGO,
                      attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                    })
                  }
                >
                  <div className={s.blogNavContainer}>
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    <div className={s.blogNav}>BLOG</div>
                  </div>
                </a>
              </Link>
            )}

            {!isBlogPage && isWhiteLabel && whiteLabelPartner && (
              <PartnerLogo
                whiteLabelPartner={whiteLabelPartner}
                isWhiteLabel={isWhiteLabel}
                visible={isSearchInputVisible && !additionalParams?.searchInputAsIcon}
              />
            )}

            {isSearchInputVisible && (
              <Search
                hasSearch={hasSearch}
                destinationId={additionalParams?.destinationId}
                searchInputAsIcon={!!additionalParams?.searchInputAsIcon}
              />
            )}
          </div>

          {isMobileView && showFeature('wishlist') && (
            <Tooltip
              active={isLoggedIn && !!wishlist.showTooltip}
              text={t('msg.easilyAccessYourWishlistsHere')}
              position="left"
              variant="white"
              behaviour="popover-overlay"
              onClose={() => {
                wishlist.setShowTooltipValue(false)
              }}
            >
              <button className={s.closeIcon} onClick={toggleMenu}>
                {menuActive ? <Icon name="delete-x" /> : <Icon name="list" />}
              </button>
            </Tooltip>
          )}

          {/* right column - currency, language, account, help center */}
          <div className={s.headerNavRight}>
            {/* Show helpCenter icon only when hamburger menu is hidden */}
            {!showFeature('hamburgerMenu') && (
              <HelpCenter variant="only-help-link" trackEvent={trackEvent} transparentBg />
            )}

            {/* Desktop View Menu Items */}
            {!isMobileView && showFeature('hamburgerMenu') && (
              <DesktopMenu
                currencyOptions={currencyOptions}
                languageOptions={languageOptions}
                showLanguageSelector={showLanguageSelector}
                variant={variant}
                state={langCurrencyState}
                toggleMenu={toggleMenu}
                avatarText={avatarText}
                handleTrackEventCallback={handleTrackEventCallback}
                handleLanguageSwitch={handleLanguageSwitch}
                handleCurrencySwitch={(nextCurrency: string) =>
                  handleCurrencySwitch(nextCurrency, !!isBlogPage)
                }
              />
            )}
          </div>
        </div>
      </div>

      {isMobileView && (
        <MobileMenu
          wishlistActive={wishlistActive}
          menuActive={menuActive}
          hasLangCurrSelector={hasLangCurrSelector}
          setwishlistActive={setwishlistActive}
          currentLocale={router.locale}
          toggleMenu={toggleMenu}
          destinationMenuActive={destinationMenuActive}
          handleTrackEventCallback={handleTrackEventCallback}
          avatarText={avatarText}
          setDestinationMenuActive={setDestinationMenuActive}
          trackEvent={trackEvent}
        />
      )}

      <DestinationModal
        open={destinationMenuActive}
        closeModal={onCloseDestinationModal}
        onDestinationChange={onCloseMenu}
        onCountryChange={onCloseMenu}
        trackEvent={trackEvent.current}
        playAnimation={false}
        shouldAutoFocus={!isMobileView}
      />
    </>
  )
}

export default Header
