import { PelagoListError } from 'gql/fragments'

export const DYNAMIC_COMPONENTS_QUERY_BODY = `
  dynamicComponents(page: $page, destinationId: $destinationId, countryId: $countryId, params: $params, entityId: $entityId, entityType: $entityType, preview: $preview, context: $context, details: $details) {
    ... on Component {
      componentId
      componentName
      componentConfig
      componentContent
      componentModifiers
      componentTrackMeta
      componentType
      componentEventId
      componentPage
      componentRank
      componentMetaType
      destinationId
      countryId
    }
    ${PelagoListError}
  }
`
