import { gql } from '@apollo/client'
import { customerFields, PelagoError } from './fragments'

export const REFRESH_TOKEN_QUERY = {
  query: {
    query: `{
      refresh {
        ...on CustomerToken {
          accessToken
        }
        ${PelagoError}
      }
    }`,
  },
  name: 'refresh',
}

export const LOGOUT_MUTATION = {
  mutation: gql`
    mutation logout {
      logout {
        ...on PelagoSuccess {
          code
        }
        ${PelagoError}
      }
    }
  `,
  name: 'logout',
}

export const LOGIN_MUTATION = {
  mutation: gql`
    mutation LogIn($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        ...on CustomerAuth {
          accessToken
          refreshToken
          customer {
            ${customerFields}
          }
        }
        ${PelagoError}
      }
    }
  `,
  name: 'login',
}

export const SOCIAL_AUTHORIZATION = {
  mutation: gql`
    mutation socialAuthorization($oauthServer: OauthServerEnum!) {
      socialAuthorization(oauthServer: $oauthServer) {
        ... on CustomerSocialInitAuth {
          authorizationUrl
          state
        }
        ${PelagoError}
      }
    }
  `,
  name: 'socialAuthorization',
}

export const SOCIAL_ONBOARDING_MUTATION = {
  mutation: gql`
    mutation socialOnboarding(
      $code: String
      $dsUserId: String,
      $provider: OauthServerEnum,
      $state: String,
      $oneTapToken: String
    ) {
      socialOnboarding(
        oauthServer: $provider,
        authorizationCode: $code,
        state: $state,
        dsUserId: $dsUserId,
        oneTapToken: $oneTapToken
      ) {
        ...on CustomerAuth {
          isCreated
          accessToken
          refreshToken
          customer {
            ${customerFields}
          }
        }
        ...on CustomerSocialAuth {
          oauthUserId
        }
        ${PelagoError}
      }
    }
  `,
  name: 'socialOnboarding',
}

export const SIGNUP_MUTATION = {
  mutation: gql`
    mutation SignUp(
      $email: String!
      $marketingEmail: Boolean
      $password: String!
      $dsUserId: String
    ) {
      signup(
        email: $email
        marketingEmail: $marketingEmail
        password: $password
        dsUserId: $dsUserId
      ) {
        ...on CustomerAuth {
          accessToken
          isCreated
          customer {
            ${customerFields}
          }
        }
        ${PelagoError}
      }
    }
  `,
  name: 'signup',
}

export const RESET_PASSWORD_REQUEST_MUTATION = {
  mutation: gql`
    mutation resetPasswordRequest(
      $email: String!
    ) {
      resetPasswordRequest(
        email: $email
      ) {
        ...on PelagoSuccess {
          code
        }
        ${PelagoError}
      }
    }
  `,
  name: 'resetPasswordRequest',
}

export const RESET_PASSWORD_MUTATION = {
  mutation: gql`
    mutation resetPassword(
      $password: String!
      $token: String!
      $marketingEmail: Boolean
    ) {
      resetPassword(
        password: $password
        token: $token
        marketingEmail: $marketingEmail
      ) {
        ...on PelagoSuccess {
          additionalDetails
          code
        }
        ${PelagoError}
      }
    }
  `,
  name: 'resetPassword',
}

export const CUSTOMER_ONBOARDING = {
  mutation: gql`
    mutation customerOnboarding(
      $email: String!
      $dsUserId: String
      $marketingEmail: Boolean
      $workflow: String
    ) {
      customerOnboarding(
        email: $email
        dsUserId: $dsUserId
        marketingEmail: $marketingEmail
        workflow: $workflow
      ) {
        ... on PelagoSuccess {
          code
          additionalDetails
        }
        ... on PelagoError {
          code
          errorMessage
          errorDetails
        }
      }
    }
  `,
  name: 'customerOnboarding',
}

export const VERIFY_OTP = {
  mutation: gql`
  mutation verify(
    $otp: String!,
    $customerId: String!,
    $email: String,
    $login: Boolean,
    $marketingEmail: Boolean,
    $isdCode: String
    $contactNumber: String
  ) {
      verifyOtp(
        otp: $otp,
        customerId: $customerId,
        email: $email,
        login: $login,
        marketingEmail: $marketingEmail,
        isdCode: $isdCode,
        contactNumber: $contactNumber
      ) {
        ...on PelagoSuccess {
          code
          additionalDetails
        }
        ...on CustomerAuth {
          accessToken
          refreshToken
          isCreated
          customer {
            ${customerFields}
          }
        }
        ...on PelagoError {
          code
          errorMessage
          errorDetails
        }
      }
    }
  `,
  name: 'verifyOtp',
}

export const RESEND_OTP = {
  mutation: gql`
    mutation sendotp(
      $email: String
      $customerId: String!
      $isdCode: String
      $contactNumber: String
      $channel: OTPChannelType
    ) {
      sendOtp(
        email: $email
        customerId: $customerId
        isdCode: $isdCode
        contactNumber: $contactNumber
        channel: $channel
      ) {
        ... on PelagoSuccess {
          code
          additionalDetails
        }
        ... on PelagoError {
          code
          errorMessage
          errorDetails
        }
      }
    }
  `,
  name: 'sendotp',
}
