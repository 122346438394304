import { gql } from '@apollo/client'

import { DYNAMIC_COMPONENTS_QUERY_BODY } from './common'

export * from './destination'
export * from './product'
export * from './wishlist'
export * from './settings'
export * from './profile'
export * from './blog'

export const DYNAMIC_COMPONENTS_QUERY = {
  query: gql`
    query dynamicComponents($page: String!, $destinationId: String, $countryId: String, $params: ComponentParams, $entityId: String, $entityType: ComponentEntityTypeEnum, $preview: Boolean, $context: DynamicComponentContext, $details: DynamicComponentInput) {
      ${DYNAMIC_COMPONENTS_QUERY_BODY}
    }
  `,
  queryName: 'dynamicComponents',
}

export const CHAT_BOOKING_DETAILS = {
  query: gql`
    query chatBookingDetails($token: String!) {
      chatBookingDetails(token: $token) {
        ... on ChatBookingDetails {
          activityDate
          bookingId
          productName
          userInfo {
            firstName
            lastName
            email
          }
        }
        ... on PelagoError {
          errorHeader
          errorMessage
          code
          errorDetails
        }
      }
    }
  `,
  queryName: 'chatBookingDetails',
}
