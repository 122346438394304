/* eslint-disable react/no-children-prop */
import React, { FC, useState, useMemo } from 'react'
import cn from 'classnames'
import { Button } from 'ui/button'
import { Icon } from 'ui/icon'
import { noop } from 'lib/utils'
import useHandleOverflowingPlaceholder from 'lib/hooks/useHandleOverflowingPlaceholder'
import { InputProps } from './types'
import s from './styles.module.scss'

const Input: FC<InputProps> = ({
  shouldIsolate = false,
  name,
  value,
  defaultValue,
  type = 'text',
  required,
  size = 'default',
  placeholder,
  placeHolderClassName,
  description,
  errorMessage,
  validMessage,
  descriptionClassName = '',
  className,
  isTextArea,
  maxLength,
  withCounter,
  contClassName,
  disabled = false,
  rows,
  readOnly = false,
  leftIconName,
  leftIconSize,
  leftIconClassName,
  onClickLeftIcon,
  rightIconName,
  rightIconSize,
  rightIconClassName,
  rightIconAsButton,
  onClickRightIcon,
  autoComplete,
  keepFocus,
  noFloat,
  noDescription,
  isWarning,
  endAdornment,
  variant,
  onKeyPress = noop,
  onChange = noop,
  onClick = noop,
  onKeyDown = noop,
  onBlur = noop,
  getRef = noop,
  hidePlaceholderAstrics = false,
  showPlaceholderAsDescription = false,
}) => {
  const [inputType, setInputType] = useState(type)
  const InputTag = isTextArea ? 'textarea' : 'input'
  const handleKeyPress = (e: any) => {
    if (onKeyPress) onKeyPress(e)

    if (type !== 'number') return

    const keyCode = e.keyCode || e.which
    const keyValue = String.fromCharCode(keyCode)
    if (!/\d+/.test(keyValue)) {
      e.preventDefault()
    }
  }
  const handlePasswordRevealInput = () => {
    if (inputType === 'password') setInputType('text')
    else setInputType(type)
  }

  const iconSize = 'medium'
  const [leftIcon, rightIcon] = useMemo(() => {
    const iconArr = []
    if (!leftIconName) iconArr.push(null)
    else {
      iconArr.push(
        React.createElement(Icon, {
          name: leftIconName,
          size: leftIconSize || iconSize,
          // eslint-disable-next-line
          className: cn(s.inputLeftIcon, s[leftIconSize || iconSize], leftIconClassName),
          onClick: onClickLeftIcon,
        })
      )
    }

    if (endAdornment) {
      iconArr.push(
        React.createElement('div', {
          className: s.inputEndAdornment,
          children: endAdornment,
        })
      )
    } else if (!rightIconName) iconArr.push(null)
    else {
      if (rightIconAsButton) {
        iconArr.push(
          React.createElement(Button, {
            iconName: rightIconName,
            shape: 'circle',
            variant: 'tertiary-grey',
            className: cn(s.inputRightIcon, s[rightIconSize || iconSize], rightIconClassName),
            onClick: onClickRightIcon,
          })
        )
      } else {
        iconArr.push(
          React.createElement(Icon, {
            name: rightIconName,
            size: rightIconSize || iconSize,
            className: cn(s.inputRightIcon, s[rightIconSize || iconSize], rightIconClassName),
            onClick: onClickRightIcon,
          })
        )
      }
    }

    return iconArr
  }, [leftIconName, rightIconName, endAdornment, onClickRightIcon]) // eslint-disable-line react-hooks/exhaustive-deps

  const passwordIcon = useMemo(() => {
    if (type === 'password') {
      return React.createElement(Icon, {
        name: inputType === 'password' ? 'eye' : 'eye-slash',
        size: iconSize,
        className: cn(s.inputRightIcon, 'cursor-pointer'),
        onClick: handlePasswordRevealInput,
      })
    }
  }, [type, inputType]) // eslint-disable-line react-hooks/exhaustive-deps

  const typedChars =
    withCounter && maxLength && value && value.length >= maxLength - 15
      ? `${value.length} / ${maxLength}`
      : null

  const { descriptionText, inputRef } = useHandleOverflowingPlaceholder(
    showPlaceholderAsDescription,
    placeholder?.trim(),
    errorMessage || validMessage || typedChars || description
  )

  return (
    <div
      className={cn(
        s.input,
        {
          [s._withError]: !!errorMessage,
          [s._withValid]: !!validMessage,
          [s._withLeftIcon]: !!leftIconName,
          [s._withRightIcon]: !!rightIconName,
          [s.isolation]: shouldIsolate,
          [s.large]: size === 'large',
        },
        s[`${leftIconSize}LeftIcon`],
        s[`${rightIconSize}RightIcon`],
        contClassName
      )}
    >
      <InputTag
        name={name}
        className={cn(s.inputElement, className)}
        value={value}
        defaultValue={defaultValue}
        type={inputType === 'number' ? 'text' : inputType}
        placeholder={placeholder}
        maxLength={maxLength || -1}
        disabled={disabled}
        rows={rows}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        ref={getRef}
        readOnly={readOnly}
        autoComplete={autoComplete}
      />
      <div
        className={cn(
          s.inputBorder,
          { [s.isRounded]: variant === 'rounded' },
          { [s.externalRef]: keepFocus }
        )}
      />
      {placeholder && (
        <p
          className={cn(
            s.inputPlaceholder,
            {
              [s.externalRef]: keepFocus && !noFloat,
              [s.noFloat]: noFloat,
            },
            placeHolderClassName
          )}
          ref={(_ref) => (inputRef.current = _ref)}
        >
          {required && !hidePlaceholderAstrics ? '* ' : ''}
          {placeholder}
        </p>
      )}
      {!noDescription && (
        <p
          className={cn(s.inputDescription, {
            [s._withWarning]: isWarning,
            [descriptionClassName]: !descriptionText,
          })}
        >
          {descriptionText}
        </p>
      )}
      {leftIcon}
      {rightIcon || passwordIcon}
    </div>
  )
}

export { Input }
