import useCustomTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'

import { DestinationItem } from '../destination-item'
import type { ItemClickFn } from '../types'

import s from './styles.module.scss'

type RecentlyViewedProps = {
  onItemClick: ItemClickFn
  shouldRedirectAfterSelect: boolean
}

const RecentlyViewed = ({ onItemClick, shouldRedirectAfterSelect }: RecentlyViewedProps) => {
  const { activityLog } = useAppData()
  const {
    globalArgs: { destinations, countries },
  } = useGlobalContext()
  const { t } = useCustomTranslation(['product'])

  if (!activityLog.destinationCountries || activityLog.destinationCountries.length === 0) {
    return null
  }

  return (
    <div className={s.recentlyViewed}>
      <h4 className={s.recentlyViewedTitle}>{t('recentlyViewed', { ns: 'product' })}</h4>
      <div>
        {activityLog.destinationCountries.map((destination) => {
          const destinationData = destinations[destination.id]
          const destinationName = destinationData?.destination_name || destination.name
          const countryName = destination.parentCountryName
            ? countries[destinationData?.country_id]?.country_name
            : ''
          const isCountry = !destination.parentCountryName

          return (
            <DestinationItem
              key={destination.name}
              name={destinationName}
              code={`${destination.code}`}
              countryName={countryName}
              countryCode={isCountry ? destination.countryCode : ''}
              uri={isCountry && !shouldRedirectAfterSelect ? destination.countryCode || '' : destination.id}
              id={destination.id}
              onClick={onItemClick}
              isCountry={!destination.parentCountryName}
              shouldRedirectAfterSelect={shouldRedirectAfterSelect}
              source="recently_viewed"
            />
          )
        })}
      </div>
    </div>
  )
}

export { RecentlyViewed }
