import { useMemo, useCallback, useState } from 'react'
import { useRouter } from 'next/router'

import useRouteMatch from 'lib/hooks/useRouteMatch'
import { useDebounce } from 'lib/hooks/useDebounce'

import { useGlobalContext } from 'lib/context/global-context'
import { buildPath, getDestinationIdFromSlug } from 'lib/utils'

import {
  COUNTRY_ROUTE,
  COUNTRY_ROUTE_V1,
  DESTINATION_ROUTE,
  DESTINATION_ROUTE_V1,
} from 'lib/constants/routes'

export const useDestinationSwitcher = (props?: {
  defaultLocaleValues?: Record<string, string>
  debounceValue?: number
  defaultSearchQuery?: string
}) => {
  const { defaultLocaleValues } = props || {}
  const router = useRouter()
  const [searchText, setSearchText] = useState(props?.defaultSearchQuery || '')
  const { destinationId, countryId, productSlug } = router.query
  const isDefaultLocaleOnly = !!defaultLocaleValues

  const debouncedSearchText = useDebounce(searchText, props?.debounceValue || 0)

  // Check currently routed page
  const isOldDestinationRoute = Boolean(useRouteMatch(DESTINATION_ROUTE))
  const isNewDestinationRoute = Boolean(useRouteMatch(DESTINATION_ROUTE_V1))
  const isDestinationRoute = isOldDestinationRoute || isNewDestinationRoute
  const isOldCountryRoute = Boolean(useRouteMatch(COUNTRY_ROUTE))
  const isNewCountryRoute = Boolean(useRouteMatch(COUNTRY_ROUTE_V1))
  const isCountryRoute = isOldCountryRoute || isNewCountryRoute

  const activeDestinationId = useMemo(() => {
    if (destinationId) return (destinationId as string) || '' // for search route get the active dest id from route param / query param
    /**
     * TODO: Need to remove `getDestinationIdFromSlug` util method and use a solution where `destinationId` is
     * coming from single source. It require a proper fix and testing all pages so can be fixed in separate ticket.
     * Discovered during this fix: https://github.com/V287/pelago-traveller-ssr/pull/151
     */
    if (productSlug) return getDestinationIdFromSlug(productSlug as string) || ''
  }, [destinationId, productSlug])

  const activeCountryId = useMemo(() => {
    return (countryId as string) || '' // for search route get the active country id from route param / query param
  }, [countryId])

  const { globalArgs } = useGlobalContext()
  const { destination_tree, destinations, countries, countriesUri } = globalArgs || {}

  const getDestinationInfo = useCallback(
    (destId: string) => {
      return isDefaultLocaleOnly
        ? { ...destinations[destId], destination_name: defaultLocaleValues?.[destId] }
        : destinations[destId]
    },
    [destinations, isDefaultLocaleOnly, defaultLocaleValues]
  )

  const getCountryInfo = useCallback(
    (_countryId: any = countryId) => {
      return countries[countriesUri?.[_countryId]?.country_id]
    },
    [countries, countryId, countriesUri]
  )

  const goToDestinationRoute = (destinationUri: string, destinationCode: string) => {
    router.push(buildPath(DESTINATION_ROUTE_V1, { destinationId: destinationUri, destinationCode }))
  }

  const goToCountryRoute = (_countryId: string, countryCode: string) => {
    router.push(buildPath(COUNTRY_ROUTE_V1, { countryId: _countryId, countryCode }))
  }

  const destinationInfo = useMemo(() => {
    const info = Object?.entries(destination_tree || {}).map(([countryCode, destinationIds]) => ({
      countryCode,
      countryNumericCode: countries?.[countryCode]?.country_code,
      // countriesUri holds always default locale text
      // countries holds text based on user's locale
      countryName: isDefaultLocaleOnly
        ? countriesUri?.[countries?.[countryCode]?.country_uri]?.country_name
        : countries?.[countryCode]?.country_name,
      countryId: countries?.[countryCode].country_uri,
      destinations: destinationIds
        .map((destId) => getDestinationInfo(destId))
        .filter((item) => {
          if (defaultLocaleValues) {
            return item?.is_active && !!defaultLocaleValues?.[item?.destination_uri]
          }
          return item?.is_active
        }),
    }))

    return info
      ?.filter(({ countryId }) => (isDefaultLocaleOnly ? !!defaultLocaleValues?.[countryId] : true))
      ?.sort((a, b) => a.countryName.localeCompare(b.countryName))
  }, [
    destination_tree,
    countries,
    countriesUri,
    getDestinationInfo,
    defaultLocaleValues,
    isDefaultLocaleOnly,
  ])

  const filteredCountryDestinations = useMemo(() => {
    if (!debouncedSearchText) return destinationInfo
    const _searchText = debouncedSearchText.toLowerCase().trim()

    return destinationInfo?.reduce?.((acc: CountryDestInfo[], countryAndDestination: CountryDestInfo) => {
      const { countryName, destinations } = countryAndDestination

      if (countryName.toLowerCase().includes(_searchText)) {
        // show all destination and country if search text matched country name
        acc.push(countryAndDestination)
      } else {
        // show only matched destinations. if no destination macthed, do not show the country too
        const filteredDestinations = destinations?.filter?.(
          ({ destination_name }) => !!destination_name?.toLowerCase()?.includes(_searchText)
        )
        if (filteredDestinations.length) {
          acc.push({ ...countryAndDestination, destinations: filteredDestinations })
        }
      }
      return acc || []
    }, [])
  }, [debouncedSearchText, destinationInfo])

  return {
    getDestinationInfo,
    activeDestinationId,
    destinationInfo: filteredCountryDestinations,
    goToDestinationRoute,
    getCountryInfo,
    activeCountryId,
    goToCountryRoute,
    isCountryRoute,
    isDestinationRoute,
    searchText,
    debouncedSearchText,
    searchCountryDestination: setSearchText,
  }
}
