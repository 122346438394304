import React from 'react'
import cn from 'classnames'
import { Trans } from 'next-i18next'

import { Button } from 'ui/button'
import { Icon } from 'ui/icon'

import useTranslation from 'lib/hooks/useTranslation'
import useAuth from 'lib/hooks/useAuth'
import useClickOutside from 'lib/hooks/useClickOutside'

import { noop } from 'lib/utils'
import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'

import StartWishListImg from 'brand-assets/illustrations/add-to-wishlist-person.svg'

import s from './styles.module.scss'

interface WishlistDropdownProps {
  onCloseWishlist?: () => void
  subWrapperClassName?: string
  wrapperClassName?: string
}

const WishlistDropdown = ({
  onCloseWishlist = noop,
  subWrapperClassName = '',
  wrapperClassName = '',
}: WishlistDropdownProps) => {
  const { trackEvent } = useAppData()
  const { t } = useTranslation('common')
  const { authModal } = useAuth()
  const { isMobileView } = useGlobalContext()

  const wishlistRef = React.useRef<HTMLDivElement | null>(null)

  useClickOutside(wishlistRef, () => {
    onCloseWishlist()
  })

  return (
    <div
      ref={wishlistRef}
      className={wrapperClassName || cn({ [s.wishlistMobileMenu]: isMobileView, [s.active]: isMobileView })}
    >
      <div className={cn(s.wishlist, subWrapperClassName || { [s.wishlistContent]: isMobileView })}>
        <StartWishListImg alt="" className={s.wishListImage} />
        <div className={s.wishlistContentRight}>
          <p className={s.wishlistText}>
            <Trans
              i18nKey="msg.clickTapIconToSaveActivities"
              components={{
                icon: <Icon name="heart" size="small" className={s.wishlistHeart} />,
              }}
            />
            {t('lang.punctuation', { ns: 'common' })}
          </p>
          <Button
            variant="primary"
            size="small"
            className={s.signUpCta}
            onClick={() => {
              trackEvent.current?.({
                attributeId: EVENTS.FROM_WISHLIST_SIGNUP,
                attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
              })
              authModal.open({ screen: 'signup' })
            }}
          >
            {t('action.signUpToSaveActivities')}
          </Button>
          <p className={s.wishlistSubText}>
            {t('q.alreadyHaveAnAccount')}
            <Button
              variant="link-tertiary"
              size="small"
              onClick={() => {
                trackEvent.current?.({
                  attributeId: EVENTS.FROM_WISHLIST_LOGIN,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                })
                authModal.open({ screen: 'login' })
              }}
            >
              {t('action.login', { ns: 'auth' })}
            </Button>
          </p>
        </div>
      </div>
    </div>
  )
}

export { WishlistDropdown }
