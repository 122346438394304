import { useMemo } from 'react'
import { useRouter } from 'next/router'
import uuid from 'uuid-random'

import useLayoutEffect from 'lib/hooks/useLayoutEffect'

import { pageViewed, track } from 'lib/utils/track'
import { getBrowserCookie, isServer } from 'lib/utils'
import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'
import { getDsUserId, getUserStorageData } from 'lib/utils/auth'

import { COOKIES_DS_REF, REFERER_STORAGE_KEY } from 'lib/constants'

interface trackContextProps {
  pageName: string
  pageMainAttributeType?: string
  pageId?: string
  additionalParams?: any
  pageViewedDeps?: any[]
  pageViewAdditionalParams?: Record<string, string | object>
  pageViewedRequirement?: () => boolean
}

const useTracking = ({
  pageName,
  pageMainAttributeType,
  pageId,
  additionalParams,
  pageViewedDeps = [],
  // only used for page level track events
  pageViewAdditionalParams = {},
  pageViewedRequirement,
}: trackContextProps) => {
  const { geoLocation } = useGlobalContext()
  const { getTrackContextData } = useAppData()

  const { query } = useRouter()

  const otherTrackParams = useMemo(() => {
    const { customerId } = getUserStorageData()?.user || {}
    const pageViewId = uuid()
    const dsUserId = getDsUserId()
    const referer = getBrowserCookie(REFERER_STORAGE_KEY)
    return { pageViewId, dsUserId, customerId, referer }
  }, [pageId]) // eslint-disable-line react-hooks/exhaustive-deps

  const otherTrackParamsWithRef = useMemo(() => {
    const refId = query.ref || getBrowserCookie(COOKIES_DS_REF)
    return { ...otherTrackParams, ref: refId }
  }, [otherTrackParams, query.ref])

  useLayoutEffect(() => {
    if (pageViewedRequirement && !pageViewedRequirement()) return

    const _pageViewed = async () => {
      const trackParams = await getTrackContextData()

      pageViewed({
        pageName,
        attributeId: pageName,
        ...(pageMainAttributeType && { pageMainAttributeType }),
        ...trackParams,
        ...otherTrackParamsWithRef,
        ...additionalParams,
        ...pageViewAdditionalParams,
        // once city header is removed from ngnix, we can remove all the references to trackParams?.geoCity
        geoCity: trackParams?.geoCity || geoLocation?.cityName,
      })

      // push to GTM -> to -> customer.io
      window?.dataLayer?.push?.({
        countryId: trackParams?.geoCountryCode,
        cityId: trackParams?.geoCity || geoLocation?.cityName,
      })
    }

    _pageViewed()
  }, pageViewedDeps) // eslint-disable-line react-hooks/exhaustive-deps

  const trackEvent = async (params: any, options?: any) => {
    if (isServer) return

    const trackParams = await getTrackContextData()
    track({
      pageName,
      ...(pageMainAttributeType && { pageMainAttributeType }),
      ...trackParams,
      ...otherTrackParamsWithRef,
      ...additionalParams,
      ...params,
      ...(options?.pageNameAsAttributeId && { attributeId: pageName }),
      geoCity: trackParams?.geoCity || geoLocation?.cityName,
    })
  }

  const formatEventAttribute = (args: string[]) => {
    return args.reduce((prevId, currId) => `${prevId}_${currId}`)
  }

  return {
    trackEvent,
    formatEventAttribute,
    pageViewId: additionalParams?.pageViewId || otherTrackParamsWithRef.pageViewId,
  }
}

export default useTracking
