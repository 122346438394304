import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'

import { DestinationModal } from 'components/destination-modal'
import { ExternalLink } from 'components/external-link'
import { LangCurrencyDropdown } from 'components/lang-currency/dropdown'
import { LangCurrencyDrawer } from 'components/lang-currency/drawer'
import { useLangAndCurrency } from 'components/lang-currency/hooks'
import { Container } from 'components/container'
import { Link } from 'components/link'

import useTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'
import { buildPath } from 'lib/utils'

import { HOME_ROUTE } from 'lib/constants/routes'
import { EVENTS } from 'lib/constants/events'
import {
  COOKIE_POLICY_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
  HELPCENTER_LINK,
  CAREERS_LINK,
  ABOUT_US_LINK,
  BLOG_LINK,
} from 'lib/constants'

import PelagoLogo from 'brand-assets/illustrations/logo/pelago-by-sg-airline-desktop-white-yellow.svg'

import { PartnerModal } from './partner-modal'
import { DropdownButton } from '../dropdown-button'
import { LayoutButton as Button } from '../button'

import s from './styles.module.scss'

const Footer = ({ isBlogPage }: { isBlogPage?: boolean }) => {
  const router = useRouter()

  const { t, join, tsmart } = useTranslation()
  const { trackEvent } = useAppData()
  const { currentCurrency, isTabletView, yellowAi } = useGlobalContext()

  const [destinationMenuActive, setDestinationMenuActive] = useState(false)
  const [partnerWithUsModalActive, setPartnerWithUsModalActive] = useState(false)

  // track event should be passed as fn for proper reference
  const {
    showLanguageSelector,
    currencyOptions,
    currencyDrawerActive,
    handleCurrencySwitch,
    openCurrencyDrawer,
    closeCurrencyDrawer,
    languageOptions,
    langDrawerActive,
    handleLanguageSwitch,
    openLangDrawer,
    closeLangDrawer,
    langCurrencyState,
  } = useLangAndCurrency({
    trackEvent: (args: any) => trackEvent.current(args),
    currCurrencyId: currentCurrency || '',
    currLocaleId: router.locale || '',
  })

  const handleLangCurrClick = (type: 'language' | 'currency') => {
    trackEvent.current({
      attributeId: type === 'language' ? EVENTS.FOOTER_LANGUAGE : EVENTS.FOOTER_CURRENCY,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
    })
    if (isTabletView) {
      // set dropdown with set timeout so that click outside is ignored
      if (type === 'currency') {
        openCurrencyDrawer()
      } else if (type === 'language') {
        openLangDrawer()
      }
    }
  }

  const onCloseDestinationModal = useCallback(() => {
    setDestinationMenuActive(false)
  }, [])

  return (
    <footer className={s.footer}>
      <Container>
        <div className={s.groupOne}>
          <Link href={buildPath(HOME_ROUTE)}>
            <a
              className={s.logo}
              onClick={() =>
                trackEvent.current?.({
                  attributeId: EVENTS.FOOTER_LOGO,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                })
              }
            >
              <PelagoLogo alt="pelago logo" />
            </a>
          </Link>
          <div className={cn(s.menus)}>
            <Button
              iconName="map-pin"
              size="small"
              onClick={() => {
                trackEvent.current({
                  attributeId: EVENTS.FOOTER_DESTINATION,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
                })
                setDestinationMenuActive(true)
              }}
              labelResponsive
              labelResponsiveClassname={s.responsiveLabel}
            >
              {t('t.destination', { count: 2, ns: 'common' })}
            </Button>
            <ExternalLink
              href={BLOG_LINK}
              noRel
              onClick={() =>
                trackEvent.current?.({
                  attributeId: EVENTS.FOOTER_ARTICLE,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
                })
              }
            >
              <Button
                iconName="newspaper"
                size="small"
                labelResponsive
                labelResponsiveClassname={s.responsiveLabel}
              >
                {tsmart('t.blog', { ns: 'common' })}
              </Button>
            </ExternalLink>
            <Button
              iconName="chat-smile"
              size="small"
              onClick={() => {
                yellowAi.showAndToggle()

                trackEvent.current?.({
                  attributeId: EVENTS.FOOTER,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.CHATBOT,
                })
              }}
              labelResponsive
              labelResponsiveClassname={s.responsiveLabel}
            >
              {tsmart('t.virtualAssistant', { ns: 'common' })}
            </Button>
            <ExternalLink
              href={HELPCENTER_LINK}
              onClick={() =>
                trackEvent.current?.({
                  link: HELPCENTER_LINK,
                  attributeId: EVENTS.FOOTER_HELP,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                })
              }
            >
              <Button
                iconName="information-outline"
                size="small"
                labelResponsive
                labelResponsiveClassname={s.responsiveLabel}
              >
                {tsmart('t.helpCenter', { ns: 'common' })}
              </Button>
            </ExternalLink>
            <ExternalLink href={ABOUT_US_LINK}>
              <Button
                iconName="pelago"
                size="small"
                labelResponsive
                labelResponsiveClassname={s.responsiveLabel}
              >
                {tsmart('t.aboutUs', { ns: 'common' })}
              </Button>
            </ExternalLink>
            <Button
              iconName="handwaving"
              size="small"
              onClick={() => {
                setPartnerWithUsModalActive(true)

                trackEvent.current?.({
                  attributeId: EVENTS.FOOTER,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.CHATBOT,
                })
              }}
              labelResponsive
              labelResponsiveClassname={s.responsiveLabel}
            >
              {tsmart('t.partnerWithUs', { ns: 'common' })}
            </Button>
            <ExternalLink href={CAREERS_LINK}>
              <Button
                iconName="briefcase"
                size="small"
                labelResponsive
                labelResponsiveClassname={s.responsiveLabel}
              >
                {t('t.careers', { ns: 'common' })}
              </Button>
            </ExternalLink>
          </div>
        </div>
        <div className={s.groupTwo}>
          <span className={cn(s.copyRightText, 'text-grey-5')}>
            {join(
              [
                t('t.encountersPteLtdWithYear', { year: new Date().getFullYear() }),
                t('msg.allRightsReserved'),
                t('lang.labelColonValue', {
                  label: t('t.travelAgentLicense', { ns: 'common' }),
                  value: 'TA03351',
                  ns: 'common',
                }),
              ],
              { endsWithPunctuation: false }
            )}
          </span>
          <div className={cn(s.termsAndPolicies, 'text-grey-5')}>
            <ExternalLink href={PRIVACY_POLICY_LINK}>
              {tsmart('t.privacyPolicy', { ns: 'common' })}
            </ExternalLink>
            <ExternalLink href={COOKIE_POLICY_LINK}>
              {tsmart('t.cookiePolicy', { ns: 'common' })}
            </ExternalLink>
            <ExternalLink href={TERMS_OF_USE_LINK}>{tsmart('t.termsOfUse', { ns: 'common' })}</ExternalLink>
          </div>

          <div className={s.currencyLangSocialLogos}>
            <div className={s.currencyLang}>
              <DropdownButton
                iconName="currency-converter"
                direction="top"
                size="small"
                dropdown={
                  <LangCurrencyDropdown
                    activeTab="currency"
                    border="none"
                    currencyOptions={currencyOptions}
                    selectedCurrencyId={langCurrencyState.currencyId}
                    onCurrencySwitch={(nextCurrency: string) =>
                      handleCurrencySwitch(nextCurrency, !!isBlogPage)
                    }
                  />
                }
                className="paddingless"
                dropDownClassName={s.langCurrencyContent}
                labelResponsive
                labelResponsiveClassname={s.responsiveLabel}
                onClick={() => handleLangCurrClick('currency')}
              >
                {langCurrencyState.currencyId}
              </DropdownButton>

              {showLanguageSelector && (
                <DropdownButton
                  iconName="globe-simple"
                  size="small"
                  direction="top"
                  dropdown={
                    <LangCurrencyDropdown
                      activeTab="language"
                      border="none"
                      onLanguageSwitch={handleLanguageSwitch}
                      selectedLangId={langCurrencyState.langId}
                      languageOptions={languageOptions}
                    />
                  }
                  dropDownClassName={s.langCurrencyContent}
                  className="paddingless"
                  labelResponsive
                  labelResponsiveClassname={s.responsiveLabel}
                  onClick={() => handleLangCurrClick('language')}
                >
                  {langCurrencyState.langLabel}
                </DropdownButton>
              )}
            </div>
            <div className={s.socialLogos}>
              <ExternalLink href="https://www.tiktok.com/@discoverpelago">
                <Button iconName="tiktok-round" size="medium" shape="circle" />
              </ExternalLink>
              <ExternalLink href="//www.facebook.com/discoverpelago/">
                <Button iconName="facebook-round" size="medium" shape="circle" />
              </ExternalLink>
              <ExternalLink href="//www.instagram.com/discoverpelago/">
                <Button iconName="instagram-round" size="medium" shape="circle" />
              </ExternalLink>
              <ExternalLink href="//www.linkedin.com/company/discoverpelago/about/">
                <Button iconName="linkedin-round" size="medium" shape="circle" />
              </ExternalLink>
            </div>
          </div>
        </div>
      </Container>
      <LangCurrencyDrawer
        belowHeader
        showLanguageSelector={showLanguageSelector}
        // currency
        selectedCurrencyId={langCurrencyState.currencyId}
        currencyOptions={currencyOptions}
        currencyDrawerActive={currencyDrawerActive}
        onCurrencyDrawerClose={closeCurrencyDrawer}
        onCurrencySwitch={handleCurrencySwitch}
        //language
        selectedLangId={langCurrencyState.langId}
        languageOptions={languageOptions}
        langDrawerActive={langDrawerActive}
        onLangDrawerClose={closeLangDrawer}
        onLanguageSwitch={handleLanguageSwitch}
      />

      <DestinationModal
        open={destinationMenuActive}
        closeModal={onCloseDestinationModal}
        trackEvent={trackEvent.current}
      />

      <PartnerModal open={partnerWithUsModalActive} onClose={() => setPartnerWithUsModalActive(false)} />
    </footer>
  )
}

export default Footer
