import { ParsedUrlQuery } from 'querystring'
import { IncomingMessage, ServerResponse } from 'http'

import { NextPageContext } from 'next'
import { useRouter } from 'next/router'
import { GetServerSidePropsContext } from 'next/types'
import cookie from 'cookie'
import { onError } from '@apollo/client/link/error'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  fromPromise,
  createHttpLink,
} from '@apollo/client'

import {
  getError,
  isBrowser,
  isNumber,
  isServer,
  logError,
  serialiseCookie,
  setBrowserCookie,
} from 'lib/utils'
import { forceLogout } from 'lib/utils/auth'

import {
  FALLBACK_CURRENCY,
  FALLBACK_LOCALE,
  COOKIES_CURRENCY,
  COOKIES_DS_USER_ID,
  COOKIES_REFRESH_TOKEN,
  COOKIES_TOKEN,
  COOKIES_DS_SESSION_ID,
  UTM_STORAGE_KEY,
  HEADER_CLOUDFRONT_LATITUDE,
  HEADER_CLOUDFRONT_LONGITUDE,
  HEADER_CLOUDFRONT_COUNTRY,
  COOKIES_FIRST_CLICK,
  COOKIES_LAST_CLICK,
  COOKIES_LAST_30_CLICK,
  HEADER_CURRENCY,
  APOLLO_CLIENT_VERSION,
  COOKIES_PARTNER_SESSION_ID,
  PARTNER_LIST,
} from 'lib/constants'

import { LOGOUT_MUTATION, REFRESH_TOKEN_QUERY } from 'gql/auth'

export type ResolverContext = {
  req: IncomingMessage
  res: ServerResponse
}

// SSR_GRAPHQL is set by circle ci to call the graphql end point locally for server side rendering
// this env variable is not available for local development
const DEFAULT_GRAPHQL_ENDPOINT = process.env.SSR_GRAPHQL || process.env.NEXT_PUBLIC_GRAPHQL
const CONTENTFUL_DELIVERY_ENDPOINT = process.env.CONTENTFUL_DELIVERY_ENDPOINT
const SEARCH_GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_SEARCH_GRAPHQL_ENDPOINT

const clientVersionMap = {
  [APOLLO_CLIENT_VERSION.CONTENTFUL_PREVIEW]: {
    uri: CONTENTFUL_DELIVERY_ENDPOINT,
    token: process.env.CONTENTFUL_PREVIEW_TOKEN,
  },
  [APOLLO_CLIENT_VERSION.CONTENTFUL_DELIVERY]: {
    uri: CONTENTFUL_DELIVERY_ENDPOINT,
    token: process.env.CONTENTFUL_DELIVERY_TOKEN,
  },
  [APOLLO_CLIENT_VERSION.SEARCH]: {
    uri: SEARCH_GRAPHQL_ENDPOINT,
  },
  [APOLLO_CLIENT_VERSION.PELAGO_CORE]: {
    uri: DEFAULT_GRAPHQL_ENDPOINT,
  },
}

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

const getUtmsValue = (cookies: { [key: string]: string }) => {
  const lastUtm = cookies[UTM_STORAGE_KEY]
  const firstClick = cookies[COOKIES_FIRST_CLICK]
  const lastClick = cookies[COOKIES_LAST_CLICK]
  const last30Click = cookies[COOKIES_LAST_30_CLICK]
  const utms: { lastUtm?: string; firstClick?: string; lastClick?: string; last30Click?: string } = {}

  if (lastUtm?.trim?.()) {
    try {
      // if someone change value in cookie directly with simple string, json parsing will fail so need to take care
      utms.lastUtm = JSON.parse(lastUtm)
    } catch (e) {
      // nothing to do
    }
  }
  if (firstClick?.trim?.()) {
    try {
      utms.firstClick = JSON.parse(firstClick)
    } catch (e) {
      // nothing to do
    }
  }
  if (lastClick?.trim?.()) {
    try {
      utms.lastClick = JSON.parse(lastClick)
    } catch (e) {
      // nothing to do
    }
  }
  if (last30Click?.trim?.()) {
    try {
      // if someone change value in cookie directly with simple string, json parsing will fail so need to take care
      utms.last30Click = JSON.parse(last30Click)
    } catch (e) {
      // nothing to do
    }
  }
  return utms
}

export const getHttpHeaders = (
  context?: GetServerSidePropsContext | NextPageContext,
  {
    locale,
    latitude,
    longitude,
    countryCode,
    operationName,
    currencyFromQuery,
  }: {
    operationName?: string
    locale?: string
    latitude?: number
    longitude?: number
    countryCode?: string
    currencyFromQuery?: string
  } = {}
) => {
  if (isBrowser && !locale) {
    throw new Error('locale is required while initialising apollo client from browser')
  }

  // @ts-ignore
  let cookies: { [key: string]: string } = {}
  if (isBrowser) cookies = cookie.parse(document.cookie)
  else cookies = cookie.parse(context?.req?.headers?.cookie || '')
  let dsUserId = cookies[COOKIES_DS_USER_ID]
  let currency = cookies[COOKIES_CURRENCY]
  let token = cookies[COOKIES_TOKEN]
  const refreshToken = cookies[COOKIES_REFRESH_TOKEN]
  const sessionId = cookies[COOKIES_DS_SESSION_ID]
  const utms = getUtmsValue(cookies)

  let webviewCookieInfo: any

  try {
    webviewCookieInfo = cookies[COOKIES_PARTNER_SESSION_ID]
      ? JSON.parse(cookies[COOKIES_PARTNER_SESSION_ID])
      : undefined
  } catch (e) {
    // nothing to do
  }

  // const encodedUtms: { [key: string]: string } = {}
  // if (utms) {
  //   const utmKeys = Object.keys(utms)
  //   utmKeys?.map((key: any) => (encodedUtms[encodeURIComponent(key)] = encodeURIComponent(utms[key])))
  // }

  // if required cookie is not available in user's req object, it will be set by _app getInitialProps
  const responseCookies = context?.res?.getHeader('Set-Cookie')

  if (isServer && Array.isArray(responseCookies)) {
    responseCookies.forEach((item: string) => {
      const respCookie = cookie.parse(item)

      // always use the new token if available (set by error link)
      if (respCookie[COOKIES_TOKEN]) token = respCookie[COOKIES_TOKEN]
      if (!dsUserId && respCookie[COOKIES_DS_USER_ID]) dsUserId = respCookie[COOKIES_DS_USER_ID]
      if (!currency && respCookie[COOKIES_CURRENCY]) currency = respCookie[COOKIES_CURRENCY]

      try {
        if (!webviewCookieInfo) {
          webviewCookieInfo = respCookie[COOKIES_PARTNER_SESSION_ID]
            ? JSON.parse(respCookie[COOKIES_PARTNER_SESSION_ID])
            : undefined
        }
      } catch (e) {
        // nothing to do
      }
    })
  }

  if (!webviewCookieInfo) {
    const { partnerId, sessionId } = context?.query || {}
    webviewCookieInfo = { partnerId, sessionId }
  }
  const _locale = (context?.locale !== '__default' && context?.locale) || locale || FALLBACK_LOCALE

  // backend is using locale split method to get the country code for translation
  // so they expect locale to be in the format of xx-XX
  // once they remove this dependency, we can remove this mapping
  const feToBeLocale: Record<string, string> = {
    en: 'en-US',
    id: 'id-ID',
    th: 'th-TH',
    vi: 'vi-VN',
    ja: 'ja-JP',
    ko: 'ko-KR',
    zh: 'zh-Hans-CN',
  }
  const headerLocale = feToBeLocale[_locale] || _locale

  const headers: any = {
    'Content-Type': 'application/json',
    [HEADER_CURRENCY]: currencyFromQuery || currency || FALLBACK_CURRENCY,
    'X-Locale': headerLocale,
    'X-Domain': process.env.NEXT_PUBLIC_HOST, // BE read this to differentiate .co & .com domain (domain migration period)
    ...(!!dsUserId && { 'X-DS-User-Id': dsUserId }),
    ...(!!sessionId && { 'X-DS-Session-Id': sessionId }),
    // ...(!!utms && { 'X-utm': encodedUtms }),
    ...(Object.keys(utms).length && { 'X-utm': JSON.stringify(utms) }),
    ...(isNumber(latitude) && { 'X-Geo-Latitude': latitude }),
    ...(isNumber(longitude) && { 'X-Geo-Longitude': longitude }),
    ...(!!countryCode && { 'X-Geo-Country-Code': countryCode }),
    ...(isServer && { 'X-Whitelist': 'traveller-ssr' }),
    ...(webviewCookieInfo?.partnerId && {
      'X-Partner-Id': webviewCookieInfo?.partnerId || PARTNER_LIST[0],
      'X-Partner-Session-Id': webviewCookieInfo?.sessionId,
    }),
  }

  if ([LOGOUT_MUTATION.name, REFRESH_TOKEN_QUERY.name].includes(operationName || '')) {
    // switch token usage for Headers of logout/refresh operations
    // Auth uses `refreshToken`, X-Access-Token uses `token`
    headers.Authorization = `Bearer ${refreshToken}`
    headers['X-Access-Token'] = token
  } else if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}

function createApolloClient(
  context?: GetServerSidePropsContext | NextPageContext,
  config?: {
    locale?: string
    pathname?: string
    query?: ParsedUrlQuery
    geoLocation: GeoLocation
  }
) {
  const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
    // catch network error thrown by other
    // @ts-ignore
    if (networkError?.statusCode === 422) {
      return fromPromise(
        // eslint-disable-next-line
        fetch(process.env.NEXT_PUBLIC_GRAPHQL!, {
          method: 'POST',
          headers: getHttpHeaders(context, {
            operationName: REFRESH_TOKEN_QUERY.name,
            locale: config?.locale,
            latitude: config?.geoLocation?.latitude,
            longitude: config?.geoLocation?.longitude,
            countryCode: config?.geoLocation?.countryCode,
          }),
          body: JSON.stringify(REFRESH_TOKEN_QUERY.query),
        })
          .then((res) => res.json())
          .then(({ data }) => {
            // force log out on any token refresh errors
            const err = getError(data[REFRESH_TOKEN_QUERY.name])
            if (err.code) {
              throw new Error(err.errorMessage)
            }

            const accessToken = data[REFRESH_TOKEN_QUERY.name]?.accessToken
            if (isServer) {
              context?.res?.setHeader('Set-Cookie', [serialiseCookie(COOKIES_TOKEN, accessToken)])
            } else {
              setBrowserCookie(COOKIES_TOKEN, accessToken)
            }
          })
          .catch((error) => {
            logError(error)
            return forceLogout(context, { pathname: config?.pathname, query: config?.query })
          })
      ).flatMap(() => forward(operation))
    }

    const { operationName, variables } = operation
    // 'Load failed' is actual network failure so we exclude it flowing in sentry, don't flud the sentry
    networkError?.message !== 'Load failed' &&
      logError(new Error(`${operationName} GraphQL Error`), {
        networkError,
        graphQLErrors,
        variables,
      })
  })

  const httpHeaderLink = new ApolloLink((operation, forward) => {
    const { headers, version = APOLLO_CLIENT_VERSION.PELAGO_CORE } = operation.getContext()
    const clientVersion = clientVersionMap[version]

    if (clientVersion && 'token' in clientVersion) {
      operation.setContext({
        headers: {
          ...headers,
          Authorization: `Bearer ${clientVersion.token}`,
        },
      })
    } else {
      operation.setContext({
        headers: {
          ...headers,
          ...getHttpHeaders(context, {
            locale: config?.locale,
            operationName: operation?.operationName,
            latitude: config?.geoLocation?.latitude,
            longitude: config?.geoLocation?.longitude,
            countryCode: config?.geoLocation?.countryCode,
            currencyFromQuery: headers?.[HEADER_CURRENCY] || null,
          }),
        },
      })
    }

    return forward(operation)
  })

  const dataLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response: any) => {
      if (operation?.operationName) {
        const { errorMessage, code } = response.data[operation.operationName] || {}

        if (code === 422) {
          // expired token
          // throw fake network error so errorLink can catch it
          const error = new Error(errorMessage)
          // @ts-ignore
          error.statusCode = code
          throw error
        }

        if (code === 401) {
          forceLogout(context, { pathname: config?.pathname, query: config?.query })
          return response
        }
      }
      return response
    })
  })

  const httpLink = createHttpLink({
    uri: ({ getContext }) => {
      const { version = APOLLO_CLIENT_VERSION.PELAGO_CORE } = getContext()

      return clientVersionMap[version].uri
    },
    fetch,
  })

  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development',
    ssrMode: isServer,
    link: from([errorLink, httpHeaderLink, dataLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  })
}

export function initializeApollo(
  context?: GetServerSidePropsContext | NextPageContext,
  config?: { locale?: string; pathname?: string; query?: ParsedUrlQuery; geoLocation?: GeoLocation }
) {
  const headers = context?.req?.headers
  // config?.geoLocation is used for client side
  // headers?.[HEADER_CLOUDFRONT] is used for SSR
  const latitude = (config?.geoLocation?.latitude || headers?.[HEADER_CLOUDFRONT_LATITUDE]) as string
  const longitude = (config?.geoLocation?.longitude || headers?.[HEADER_CLOUDFRONT_LONGITUDE]) as string
  const countryCode = (config?.geoLocation?.countryCode || headers?.[HEADER_CLOUDFRONT_COUNTRY]) as string

  const geoLocation = {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
    countryCode,
  }
  const _apolloClient = apolloClient ?? createApolloClient(context, { ...config, geoLocation })

  // For SSG and SSR always create a new Apollo Client
  if (isServer) return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo({ geoLocation }: { geoLocation: GeoLocation }) {
  const router = useRouter()

  const apolloClient = initializeApollo(undefined, {
    locale: router.locale,
    pathname: router.pathname,
    query: router.query,
    geoLocation,
  })

  return apolloClient
}
