import React, { createContext, useState, useMemo, useEffect, useCallback } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import uniq from 'lodash/uniq'

import useAuth from 'lib/hooks/useAuth'

import { clearEcommerceToGa4, productToEcommerceGtm, getError, noop } from 'lib/utils'

import { DATA_LAYER_EVENT, STORAGE_WISHLIST_SHOW_TOOLTIP } from 'lib/constants'

import {
  CREATE_WISHLIST_MUTATION,
  SAVE_TO_WISHLIST_MUTATION,
  UPDATE_ITEM_IN_WISHLISTS_MUTATION,
  REMOVE_ITEM_FROM_WISHLIST_MUTATION,
  WISHLISTED_PRODUCTS,
} from 'gql'

type WishlistFuncParams = {
  productId: string
  wishlistId?: string
  wishlistIds?: string[]
  onSuccess?: (data: any) => void
  onError?: (error: any) => void
}

type CreateWishlistFuncParams = {
  wishlistName: string
  productId?: string
  isProductReset?: boolean
  onSuccess?: (data: any) => void
  onError?: (error: any) => void
}

type WishlistModalTypes = 'none' | 'create' | 'update'
type NextModalActions = 'none' | 'update' | 'redirect'

type ModalDataValues = {
  nextAction?: NextModalActions
  autoSuggestName?: boolean
  productId?: string
  selectedIds?: string[]
  destinationId?: string
  wishlistName?: string
  errorMessage?: string
  onComplete?: (data?: any) => void
  onClick?: (arg0?: any) => void
}

interface IWishlistContext {
  modal: {
    active: WishlistModalTypes
    setActive: (arg1: WishlistModalTypes) => void
    data: ModalDataValues | any
    updateData: (arg1: Record<string, any>) => void
  }
  count: number
  wishlistedIds?: string[]
  isWishlisted: (productId: string) => boolean
  showTooltip: boolean | undefined
  setShowTooltipValue: (show: any) => void
  createWishlistBucket: ({ wishlistName, productId, onSuccess, onError }: CreateWishlistFuncParams) => void
  saveToWishlist: ({ productId, onSuccess, onError }: WishlistFuncParams) => void
  updateItem: ({ productId, wishlistIds, onSuccess, onError }: WishlistFuncParams) => void
  removeItem: ({ productId, wishlistId, onSuccess, onError }: WishlistFuncParams) => void
  onProductAddedToWishlist: ({ product }: any) => void
  fetchWishlistedIds: () => void
}

const WishlistContext = createContext<IWishlistContext>({
  modal: {
    setActive: noop,
    active: 'none',
    updateData: noop,
    data: {},
  },
  count: 0,
  wishlistedIds: [],
  isWishlisted: () => false,
  showTooltip: undefined,
  setShowTooltipValue: () => false,
  createWishlistBucket: async () => undefined,
  saveToWishlist: async () => undefined,
  updateItem: async () => undefined,
  removeItem: async () => undefined,
  onProductAddedToWishlist: async () => undefined,
  fetchWishlistedIds: async () => undefined,
})

const WishlistProvider = (props: any) => {
  const { user, isLoggedIn } = useAuth()
  const [createWishlist] = useMutation(CREATE_WISHLIST_MUTATION.mutation)
  const [saveToWishlist] = useMutation(SAVE_TO_WISHLIST_MUTATION.mutation)
  const [updateItemInWishlists] = useMutation(UPDATE_ITEM_IN_WISHLISTS_MUTATION.mutation)
  const [removeItemFromWishlist] = useMutation(REMOVE_ITEM_FROM_WISHLIST_MUTATION.mutation)

  const [showTooltip, setShowTooltip] = useState<any>(undefined)
  const [wishlistModalData, setWishlistModalData] = useState<any>({})
  const [activeWishlistModal, setActiveWishlistModal] = useState<WishlistModalTypes>('none')
  const [wishlistInfo, setWishlistInfo] = useState<{
    count: number
    wishlistedIds?: string[]
    showTooltip?: boolean | undefined
  }>({
    count: 0,
    wishlistedIds: [],
  })

  const [fetchCustomerWishlistIds] = useLazyQuery(WISHLISTED_PRODUCTS.query, {
    onCompleted: (response: any) => {
      const data = response?.[WISHLISTED_PRODUCTS.queryName]
      const shouldShowTooltip = localStorage.getItem(STORAGE_WISHLIST_SHOW_TOOLTIP)

      if (!shouldShowTooltip) {
        localStorage.setItem(STORAGE_WISHLIST_SHOW_TOOLTIP, 'true')
      }

      setWishlistInfo((curr) => {
        // merge the existing wishlistIds with the new ones. Applicable in case guest user logs in and has item in wishlist
        const _updatedList = uniq([...(curr?.wishlistedIds || []), ...(data?.productIds || [])])
        return { ...curr, count: _updatedList?.length, wishlistedIds: _updatedList }
      })
    },
  })

  const handleShowTooltip = useCallback(() => {
    if (wishlistInfo.showTooltip === false) return

    const shouldShowTooltip = localStorage.getItem(STORAGE_WISHLIST_SHOW_TOOLTIP)

    if (shouldShowTooltip && shouldShowTooltip === 'true') {
      setShowTooltip(true)
    }
  }, [wishlistInfo.showTooltip])

  const hideTooltip = useCallback((show) => {
    setShowTooltip(show)
    localStorage.setItem(STORAGE_WISHLIST_SHOW_TOOLTIP, show ? 'true' : 'false')
  }, [])

  // this is for performace improvement
  // object search is O(1) so that dont have to loop through -
  // the entire array of ids to find if an item is wish listed on each render
  const wishlistIdsObject = useMemo<Record<string, boolean>>(() => {
    const { wishlistedIds } = wishlistInfo

    if (!wishlistedIds?.length) return {}

    return wishlistedIds.reduce((acc: Record<string, boolean>, value) => {
      acc[value] = true
      return acc
    }, {})
  }, [wishlistInfo])

  const isWishlistedItem = useCallback(
    (productId: string) => !!wishlistIdsObject[productId],
    [wishlistIdsObject]
  )

  const updateActiveWishlistModal = useCallback((modalName: WishlistModalTypes) => {
    if (modalName === 'none') {
      setWishlistModalData({})
    } else if (modalName === 'update') {
      setWishlistModalData((curr: any) => ({ ...curr, wishlistName: '', errorMessage: '' }))
    }

    setActiveWishlistModal(modalName)
  }, [])

  useEffect(() => {
    if (!user || !isLoggedIn) return

    fetchCustomerWishlistIds()
  }, [user, isLoggedIn, fetchCustomerWishlistIds])

  /**
   * createWishlistBucket
   *
   * @param {string} wishlistName
   *  Create new wishlist with the given `wishlistName`
   *
   * @param {string} productId
   *  Add given productId to newly creating WL
   *
   * @param {boolean} isProductReset
   *  If true, remove other WLs from the productId.
   *  Otherwise, append new WL to existing list
   */
  const createWishlistBucket = useCallback(
    async ({
      wishlistName,
      productId,
      isProductReset = false,
      onSuccess,
      onError,
    }: CreateWishlistFuncParams) => {
      try {
        const { data: response } = await createWishlist({
          variables: { wishlistName, productId, isProductReset },
        })
        const data = response?.[CREATE_WISHLIST_MUTATION.mutationName]

        const error = getError(data)
        if (error?.code) {
          throw new Error(error?.errorMessage)
        }

        if (productId) {
          // add new productId to wishlistInfo if it isn't exist
          setWishlistInfo((curr) => {
            const isAlreadyInWishlist = (curr.wishlistedIds || []).find((pId) => pId === productId)

            const newWishlistIds = curr.wishlistedIds || []

            if (!isAlreadyInWishlist) {
              newWishlistIds.push(productId)
            }

            return {
              ...curr,
              count: newWishlistIds.length,
              wishlistedIds: newWishlistIds,
            }
          })
        }

        // Show tooltip for first time (showTooltip is undefined if localstorage key isn't presnet)
        showTooltip === undefined && handleShowTooltip()

        onSuccess?.(data)
      } catch (e) {
        onError?.(e)
      }
    },
    [createWishlist, handleShowTooltip, showTooltip]
  )

  // Quick save to Wishlist or receive flat whether to create or update the wishlist from user

  /**
   * saveItemToWishlist
   *  Quick save item to WL. If can't, return toCreate flags
   *  to FE based on which respective modal will be shown to user
   *
   * - Case 1: toCreate: true (open create pop-up)
   * - Case 2: Auto added to wishlist by BE → return wishlist ID and name
   *
   * @param {string} productId
   *  Save product to WL.
   * @param {string} wishlistId
   *  Add product to given WL if wishlistId is present.
   *
   */
  const saveItemToWishlist = useCallback(
    async ({ productId, wishlistId, onSuccess, onError }: WishlistFuncParams) => {
      try {
        const { data: response } = await saveToWishlist({
          variables: { productId, wishlistId },
        })
        const data = response?.[SAVE_TO_WISHLIST_MUTATION.mutationName]

        const error = getError(data)
        if (error?.code) {
          throw new Error(error?.errorMessage)
        }

        if (!data?.toCreate) {
          // If auto added to WL, add product to wishlistInfo,

          setWishlistInfo((curr) => {
            const isAlreadyInWishlist = (curr.wishlistedIds || []).find((pId) => pId === productId)

            let newWishlistIds = curr.wishlistedIds || []

            if (!isAlreadyInWishlist) {
              newWishlistIds = [...(curr.wishlistedIds || []), productId]
            }

            return {
              ...curr,
              count: newWishlistIds?.length,
              wishlistedIds: newWishlistIds,
            }
          })

          showTooltip === undefined && handleShowTooltip()
        }

        // Callback methods will handle the further steps if toCreate is true
        onSuccess?.(data)
      } catch (e) {
        onError?.(e)
      }
    },
    [saveToWishlist, showTooltip, handleShowTooltip]
  )

  //
  /**
   * updateWishlistItem
   *  Assign single/multiple wishlists to a product
   *
   * @param {any} productId
   *  pass productId to set WL(s)
   * @param {any} wishlistIds
   *  Given `wishlistIds` will be assigned to `productId`
   *
   */
  const updateWishlistItem = useCallback(
    async ({ productId, wishlistIds, onSuccess, onError }: WishlistFuncParams) => {
      try {
        const { data: response } = await updateItemInWishlists({
          variables: { productId, wishlistIds },
        })
        const data = response?.[UPDATE_ITEM_IN_WISHLISTS_MUTATION.mutationName]

        const error = getError(data)
        if (error?.code) {
          throw new Error(error?.errorMessage)
        }

        setWishlistInfo((curr) => {
          let newWishlistIds: any

          if (!wishlistIds?.length) {
            newWishlistIds = curr.wishlistedIds?.filter((pId: string) => pId !== productId)
          } else {
            newWishlistIds = [...(curr.wishlistedIds || []), productId]
          }

          return {
            ...curr,
            count: newWishlistIds?.length,
            wishlistedIds: newWishlistIds,
          }
        })

        showTooltip === undefined && handleShowTooltip()

        onSuccess?.(data)
      } catch (e) {
        onError?.(e)
      }
    },
    [updateItemInWishlists, showTooltip, handleShowTooltip]
  )

  /**
   * removeWishlistItem
   *  Remove item from the given `wishlistId`. Used on Account > Wishlists page,
   *  where products can be removed from the WL directly on icon click
   *
   * @param {any} productId
   * @param {any} wishlistId
   */
  const removeWishlistItem = useCallback(
    async ({ productId, wishlistId, onSuccess, onError }: WishlistFuncParams) => {
      try {
        const { data: response } = await removeItemFromWishlist({
          variables: { productId, wishlistId },
        })
        const data = response?.[REMOVE_ITEM_FROM_WISHLIST_MUTATION.mutationName]

        const error = getError(data)

        if (error?.code) {
          throw new Error(error?.errorMessage)
        }

        // Refetch wishlist items because, same product might be wishliested in multiple wishlists.
        // FE doesnt maintain WL specific productIds on client-side.
        fetchCustomerWishlistIds()

        showTooltip === undefined && handleShowTooltip()

        onSuccess?.(data)
      } catch (e) {
        onError?.(e)
      }
    },
    [fetchCustomerWishlistIds, handleShowTooltip, removeItemFromWishlist, showTooltip]
  )

  const onProductAddedToWishlist = useCallback((product) => {
    clearEcommerceToGa4()
    window?.dataLayer?.push({
      event: DATA_LAYER_EVENT.ADD_TO_WISHLIST,
      ...productToEcommerceGtm(product),
    })
  }, [])

  const value = useMemo<IWishlistContext>(
    () => ({
      ...wishlistInfo,
      modal: {
        active: activeWishlistModal,
        setActive: updateActiveWishlistModal,
        data: wishlistModalData,
        updateData: setWishlistModalData,
      },
      showTooltip: showTooltip,
      setShowTooltipValue: hideTooltip,
      updateItem: updateWishlistItem,
      saveToWishlist: saveItemToWishlist,
      removeItem: removeWishlistItem,
      createWishlistBucket: createWishlistBucket,
      isWishlisted: isWishlistedItem,
      onProductAddedToWishlist: onProductAddedToWishlist,
      fetchWishlistedIds: fetchCustomerWishlistIds,
    }),
    [
      wishlistInfo,
      activeWishlistModal,
      updateActiveWishlistModal,
      wishlistModalData,
      showTooltip,
      hideTooltip,
      updateWishlistItem,
      saveItemToWishlist,
      removeWishlistItem,
      createWishlistBucket,
      isWishlistedItem,
      onProductAddedToWishlist,
      fetchCustomerWishlistIds,
    ]
  )

  return <WishlistContext.Provider value={value} {...props} />
}

function useWishlistData() {
  const context = React.useContext(WishlistContext)
  if (context === undefined) {
    throw new Error('useWishlistData must be used within a WishlistProvider')
  }
  return context
}

export { WishlistProvider, useWishlistData }
