import React, { useEffect, useMemo } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'
import cn from 'classnames'

import { Image } from 'ui/image'

import { useGlobalContext } from 'lib/context/global-context'
import { getDsUserId } from 'lib/utils/auth'
import { getBrowserCookie } from 'lib/utils'

import { COOKIES_DS_SESSION_ID, YELLOW_AI_EVENT_WHITELIST_DOMAIN } from 'lib/constants'
import {
  ARTICLE_COUNTRY_ROUTE,
  ARTICLE_DESTINATION_ROUTE,
  ARTICLE_DETAIL_ROUTE,
  ARTICLE_HOME_ROUTE,
  BOOKING_DETAILS_ROUTE,
  BOOKING_PAYMENT_ROUTE,
  BOOKING_STATUS_ROUTE,
} from 'lib/constants/routes'

import s from './styles.module.scss'

const YellowChatWidget: React.FC = () => {
  const { isMobileView, yellowAi } = useGlobalContext()

  const { pathname, query } = useRouter()
  const dsSessionId = getBrowserCookie(COOKIES_DS_SESSION_ID) ?? ''
  const dsUserId = getDsUserId() ?? ''
  const hideChatRoutes = useMemo(
    () => [
      BOOKING_DETAILS_ROUTE.substring(0, BOOKING_DETAILS_ROUTE.length - 1),
      BOOKING_PAYMENT_ROUTE.substring(0, BOOKING_PAYMENT_ROUTE.length - 1),
      BOOKING_STATUS_ROUTE.substring(0, BOOKING_STATUS_ROUTE.length - 1),
      ...(isMobileView
        ? [
            ARTICLE_HOME_ROUTE.substring(0, ARTICLE_HOME_ROUTE.length - 1),
            ARTICLE_DESTINATION_ROUTE.substring(0, ARTICLE_DESTINATION_ROUTE.length - 1),
            ARTICLE_COUNTRY_ROUTE.substring(0, ARTICLE_DESTINATION_ROUTE.length - 1),
            ARTICLE_DETAIL_ROUTE.substring(0, ARTICLE_DETAIL_ROUTE.length - 1),
          ]
        : []),
    ],
    [isMobileView]
  )

  const defaultHideIcon = isMobileView || hideChatRoutes.includes(pathname)

  useEffect(() => {
    const onMessage = (e: any) => {
      if (YELLOW_AI_EVENT_WHITELIST_DOMAIN.includes(e.origin)) {
        try {
          const eventData = JSON.parse(e.data)
          const eventCode = eventData.event_code
          const shouldHideChatIcon = eventCode === 'close-chat' && isMobileView
          if (shouldHideChatIcon) {
            window?.YellowMessengerPlugin?.hide()
          }
        } catch (e) {
          // do nothing as we are intrested only in close-chat event with json compatible string
        }
      }
    }

    window?.addEventListener('message', onMessage)

    return () => window?.removeEventListener('message', onMessage)
  }, [isMobileView])

  return yellowAi.renderScript ? (
    <>
      <Script id="yellowai-snippet" defer>
        {`window.ymConfig = {
          "autoOpen": ${query?.chat === 'auto'},
					"bot":"${process.env.NEXT_PUBLIC_YELLOWAI_BOT}",
					"host":"${process.env.NEXT_PUBLIC_YELLOWAI_HOST}",
					"hideChatButton": ${true},
					"payload": {
						ds_user_id: "${dsUserId}",
						ds_session_id: "${dsSessionId}",
						is_mobile: ${isMobileView}
					}
        };
				(function () {
					var w=window, ic=w.YellowMessenger;
					if("function"===typeof ic)ic("reattach_activator"),ic("update",ymConfig);
					else{
						var d=document,i=function(){
							i.c(arguments)
						};
						function l(){
							var e=d.createElement("script");
							e.type="text/javascript",e.async=!0,e.src="https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js";
							var t=d.getElementsByTagName("script")[0];
							t.parentNode.insertBefore(e,t)
						}
						i.q=[],i.c=function(e){i.q.push(e)},w.YellowMessenger=i,l()
					};
				})();
			`}
      </Script>
      {yellowAi.showIcon && (
        <button
          onClick={yellowAi.showAndToggle}
          className={cn(s.yellowAiButton, { [s.hide]: defaultHideIcon })}
          aria-label="Pelago Travel Assistant Powered by AI button"
        >
          <Image src={{ url: '/yellow-ai-icon.gif' }} alt="yellow ai icon" layout="fill" />
        </button>
      )}
    </>
  ) : null
}

export { YellowChatWidget }
