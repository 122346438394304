interface GoogleOneTapType {
  clientId: string
  clientUrl: string
  autoSelect?: boolean
  cancelOnTapOutside?: boolean
  context?: string
  otherOptions?: any
}

const googleOneTap = (
  {
    clientId,
    clientUrl,
    autoSelect = false,
    cancelOnTapOutside = false,
    context = 'signin',
    ...otherOptions
  }: GoogleOneTapType,
  callback: (arg0: any) => void
) => {
  if (typeof window !== 'undefined' && window.document) {
    const contextValue = ['signin', 'signup', 'use'].includes(context) ? context : 'signin'
    const googleScript = document.createElement('script')
    googleScript.src = clientUrl
    googleScript.async = true
    googleScript.defer = true
    document.head.appendChild(googleScript)
    window.onload = function () {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: callback,
          auto_select: autoSelect,
          cancel_on_tap_outside: cancelOnTapOutside,
          context: contextValue,
          ...otherOptions,
        })
        window?.google?.accounts?.id?.prompt?.()
      }
    }
  }
}

const promptGoolgeOneTap = () => {
  if (window.google) {
    window.google?.accounts?.id?.prompt?.()
  }
}

const dismissGoogleOneTap = () => {
  window.onload = function () {
    if (window.google) {
      window?.google?.accounts?.id?.cancel?.()
    }
  }
  if (document.readyState === 'complete' && window.google) {
    window?.google?.accounts?.id?.cancel?.()
  }
}

export { googleOneTap, promptGoolgeOneTap, dismissGoogleOneTap }
