import React from 'react'

import { noop } from 'lib/utils'

type InitialPosition = 'left' | 'top' | 'right' | 'bottom'

type ModalOptions = {
  isActive?: boolean
  withCloseBtn?: boolean
  content: React.ReactNode
  closeOnOverlayClick?: boolean
  closeOnEsc?: boolean
  blur?: boolean
  backgroundWhite?: boolean
  padding?: boolean
  appearFrom?: InitialPosition
}

export const initialGlobalArgs = {
  locales: {},
  categories: {},
  constants: {},
  currencies: [],
  countriesUri: {},
  allowedLocales: [],
  destinations: {},
  destination_tree: {},
  countries: {},
  search_tags: {},
  tags: {},
  promo_banner_texts: {},
  promoBannerTexts: {},
  rewardsBanner: {},
  categories_codes: {},
  tags_codes: {},
  tagDetails: {},
  whitelabel: {},
}

export interface IGlobalContext {
  globalArgs: GlobalArgs
  geoLocation?: GeoLocation
  currentCurrency: string | undefined
  campaignData?: CampaignData
  openModal: (options: ModalOptions) => void
  closeModal: () => void
  isMobileView: boolean
  isTabletView: boolean
  yellowAi: {
    renderScript: boolean
    showAndToggle: () => void
    hide: () => void
    show: () => void
    showIcon: boolean
  }
}

export const GlobalContext = React.createContext<IGlobalContext>({
  globalArgs: initialGlobalArgs,
  currentCurrency: undefined,
  openModal: noop,
  closeModal: noop,
  isMobileView: false,
  isTabletView: false,
  yellowAi: {
    renderScript: false,
    showAndToggle: noop,
    hide: noop,
    show: noop,
    showIcon: false,
  },
})

const GlobalContextProvider = GlobalContext.Provider

function useGlobalContext() {
  const context = React.useContext(GlobalContext)

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider')
  }
  return context
}

export { GlobalContextProvider, useGlobalContext }
