import { gql } from '@apollo/client'

import * as FIELDS from './../fragments'

const CustomerBookings = `
  bookingCount
  bookings {
    bookingId
    isCancellable
    bookingEmail
    bookingStatus
    bookingStatusTranslated
    paymentStatus
    activityDate
    productId
    productUri
    productName
    mediaData
    voucherUrl
    bookingStatusMessage
    bookingCurrency
    grossTotal
    subTotal
    paymentType
    kfMiles
    productId
    isReviewed
    optionName
  }
`

// used with fetch api
export const USER_QUERY_FETCH = {
  query: {
    query: `{
      me {
        ... on Customer {
          ${FIELDS.customerFields}
        }
        ${FIELDS.PelagoError}
      }
    }`,
  },
  name: 'me',
}

export const USER_QUERY = {
  query: gql`
    query me {
      me {
        ... on Customer {
          ${FIELDS.customerFields}
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'me',
}

export const CUSTOMER_BOOKING_QUERY = {
  query: gql`
    query myBookings($customerBookingState: CustomerBookingStateEnum, $page: Int, $pageSize: Int) {
      myBookings(customerBookingState: $customerBookingState, page: $page, pageSize: $pageSize) {
        ... on MyBookings {
          ${CustomerBookings}
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'myBookings',
}

export const CUSTOMER_BOOKING_DETAIL_QUERY = {
  query: gql`
    query customerBookingDetails($bookingId: String!) {
      customerBookingDetails(bookingId: $bookingId) {
        ... on CustomerBookingDetails {
          bookings {
            activityDate
            bookingEmail
            bookingId
            bookingStatus
            bookingStatusTranslated
            bookingStatusMessage
            bookingCurrency
            grossTotal
            subTotal
            isReviewed
            kfMiles
            krisflyerNumber
            loyaltyRewards
            paymentState
            paymentStatus
            paymentStatusMessage
            paymentType
            isCancellable
            bookingFormFields
            additionalNotes
            product {
              productName
              productId
              productUri
              guideLanguage
              mediaData
              optionName
              preferredTime
              specialNotes
              redemptionInstructions
              units {
                unitName
                quantity
              }
            }
            refundStatus
            voucherUrl
            cancellationType
            cancellationPolicy {
              productTimezone
              refundAmount
              title
              subTitle
              description
              textValues
              cancellationWindows {
                cancelBy
                cancelByValues
                refundPercentage
                isEligible
              }
            }
          }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'customerBookingDetails',
}
