import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'
import { Image } from 'ui/image'

import { Link } from 'components/link'
import { InfiniteScroller } from 'components/infinite-scroll'

import useCustomTranslation from 'lib/hooks/useTranslation'

import { buildPath } from 'lib/utils'
import { useAppData } from 'lib/context/app-data-context'

import { DESTINATION_ROUTE_V1 } from 'lib/constants/routes'

import type { ItemClickFn } from '../types'
import { RegionChipsLoading, DestinationsByRegionLoading } from './loading'
import { RegionChips } from './region-chips'
import { DESTINATIONS_BY_REGION_PAGE_SIZE } from '../constants'

import s from './styles.module.scss'

const TOP_10_REGION_ID = 'topTrendingDestinations'

type ExploreDestinationsProps = {
  regions: Region[] | undefined
  destinationsByRegion: DestinationByRegion | undefined
  fetchMoreDestinationsByRegion: () => void
  shouldRedirectAfterSelect?: boolean
  initialLoading: boolean
  isLoading: boolean
  currentPage: number
  onItemClick: ItemClickFn
}

const ExploreDestinations = ({
  regions,
  shouldRedirectAfterSelect,
  destinationsByRegion,
  initialLoading,
  isLoading,
  currentPage,
  fetchMoreDestinationsByRegion,
  onItemClick,
}: ExploreDestinationsProps) => {
  const { t } = useCustomTranslation('common')
  const { activityLog } = useAppData()
  const regionWithTop10 = useMemo(() => {
    if (!regions) {
      return []
    }

    return [
      {
        regionId: TOP_10_REGION_ID,
        regionName: t('t.topTen', { ns: 'common' }),
        regionCode: 0,
        continentCode: TOP_10_REGION_ID,
      } as Region,
      ...regions,
    ]
  }, [regions, t])

  const [selectedRegionId, setSelectedRegionId] = useState(regionWithTop10?.[0]?.regionId || '')

  useEffect(() => {
    if (regionWithTop10.length > 0) {
      setSelectedRegionId(regionWithTop10[0].regionId)
    }
  }, [regionWithTop10])

  const destinations = useMemo(() => {
    if (selectedRegionId === TOP_10_REGION_ID) {
      return destinationsByRegion?.topTrendingDestinations.destinations
    }

    const foundRegionGroup = destinationsByRegion?.groupedByRegion.find(
      (regionGroup) => regionGroup.region.regionId === selectedRegionId
    )

    return foundRegionGroup?.destinationList?.destinations || []
  }, [selectedRegionId, destinationsByRegion])

  const renderDestinationButton = useCallback(
    (destination: RegionDestination, index) => {
      return (
        <button
          onClick={() =>
            onItemClick(destination.uri, {
              isCountry: false,
              source: selectedRegionId,
              name: destination.name,
              parentCountryName: destination.countryName,
              code: `${destination.code}`,
            })
          }
          type="button"
          key={`${destination.uri}-${index}-${selectedRegionId}`}
          className={s.destination}
          style={
            { '--stagger-delay': `${(index % DESTINATIONS_BY_REGION_PAGE_SIZE) * 0.02}s` } as CSSProperties
          }
        >
          <div className={s.destinationContentContainer}>
            <div className={s.destinationImageContainer}>
              <Image
                src={destination.thumbnail}
                size="xsmall"
                alt={destination.name}
                layout="fill"
                lazyLoad={false}
                className={s.destinationImage}
              />
            </div>
            <div className={s.destinationContent}>
              <p className={s.destinationContentName}>{destination.name}</p>
              <span className={s.destinationContentCountry}>{destination.countryName}</span>
            </div>
          </div>
          <div className={s.destinationIcon}>
            <Icon name="arrow-right" />
          </div>
        </button>
      )
    },
    [onItemClick, selectedRegionId]
  )

  const renderDestinations = useCallback(() => {
    if (!destinations || !destinations.length) return null

    return (
      <div className={s.destinationContainer}>
        {destinations.map((destination, index) => {
          return !shouldRedirectAfterSelect ? (
            renderDestinationButton(destination, index)
          ) : (
            <Link
              key={`${destination.uri}-${index}-${selectedRegionId}`}
              href={buildPath(DESTINATION_ROUTE_V1, {
                destinationId: destination.destinationId,
                destinationCode: `${destination.code}`,
              })}
            >
              {renderDestinationButton(destination, index)}
            </Link>
          )
        })}
      </div>
    )
  }, [destinations, shouldRedirectAfterSelect, renderDestinationButton, selectedRegionId])

  const hasRecentlyViewed = !!activityLog.destinationCountries?.length
  const totalPages = useMemo(() => {
    if (selectedRegionId === TOP_10_REGION_ID) return 1

    const selectedRegion = destinationsByRegion?.groupedByRegion.find(
      (regionGroup) => regionGroup.region.regionId === selectedRegionId
    )
    if (!selectedRegion) return 1

    return Math.ceil(selectedRegion.destinationList.destinationCount / DESTINATIONS_BY_REGION_PAGE_SIZE)
  }, [selectedRegionId, destinationsByRegion])

  const hasMore = currentPage < totalPages

  return (
    <>
      <div className={s.header}>
        <h3 className={cn(s.title, { [s.titleMd]: hasRecentlyViewed })}>
          {t('t.exploreNumPlusDestinations', { num: 2000 })}
        </h3>
        {initialLoading ? (
          <RegionChipsLoading />
        ) : (
          <RegionChips
            regions={regionWithTop10}
            selectedRegionId={selectedRegionId}
            setSelectedRegionId={setSelectedRegionId}
          />
        )}
      </div>
      {initialLoading ? (
        <DestinationsByRegionLoading />
      ) : selectedRegionId === TOP_10_REGION_ID ? (
        renderDestinations()
      ) : (
        <InfiniteScroller
          onFetchMoreCallback={fetchMoreDestinationsByRegion}
          results={renderDestinations()}
          hasMore={hasMore}
          isLoading={isLoading}
          showLoader
        />
      )}
    </>
  )
}

export { ExploreDestinations }
