import { Query } from 'lib/@Types'

import {
  DYNAMIC_COMPONENTS_QUERY,
  PRODUCT_QUERY,
  YOU_MAY_ALSO_LIKE_PRODUCTS_QUERY,
  PRODUCT_CARDS_QUERY,
  BOOKING_INTENT_QUERY,
  BOOKING_QUERY,
  CHAT_BOOKING_DETAILS,
  ALL_DESTINATIONS_QUERY,
  PRODUCT_FILTER_QUERY,
  ARTICLE_LIST,
  RANKED_PRODUCT_CARDS_QUERY,
  DATA_CARD_LIST,
  RECENTLY_VIEWED_PRODUCTS_QUERY,
  RECOMMENDED_PRODUCTS_QUERY,
  DESTINATION_CARDS_QUERY,
  PINNED_PRODUCT_REVIEWS,
  COLLECTION_DESTINATIONS_QUERY,
  NEAR_BY_COLLECTIONS_QUERY,
  DS_PRODUCTS,
  COMPONENT_FILTER_PRODUCTS_QUERY,
  THEME_COLLECTION_FILTER_PRODUCTS_QUERY,
} from 'gql'

interface QueriesMapping {
  [key: string]: Query
}

const queriesMapping: QueriesMapping = {
  recommendedForYou: RECOMMENDED_PRODUCTS_QUERY,
  dynamicComponents: DYNAMIC_COMPONENTS_QUERY,
  product: PRODUCT_QUERY,
  recentlyViewed: RECENTLY_VIEWED_PRODUCTS_QUERY,
  productRelatedProducts: YOU_MAY_ALSO_LIKE_PRODUCTS_QUERY,
  productCards: PRODUCT_CARDS_QUERY,
  bookingIntent: BOOKING_INTENT_QUERY,
  booking: BOOKING_QUERY,
  chatBookingDetails: CHAT_BOOKING_DETAILS,
  searchFilterProducts: PRODUCT_FILTER_QUERY,
  componentFilterProducts: COMPONENT_FILTER_PRODUCTS_QUERY,
  dsProducts: DS_PRODUCTS,
  allDestinations: ALL_DESTINATIONS_QUERY,
  destinationCards: DESTINATION_CARDS_QUERY,
  articlesComponent: ARTICLE_LIST,
  dataCardComponent: DATA_CARD_LIST,
  rankedProductCards: RANKED_PRODUCT_CARDS_QUERY,
  pelagoReviews: PINNED_PRODUCT_REVIEWS,
  collectionDestinationCards: COLLECTION_DESTINATIONS_QUERY,
  collections: NEAR_BY_COLLECTIONS_QUERY,
  themeCollectionFilterProducts: THEME_COLLECTION_FILTER_PRODUCTS_QUERY,
}

export default queriesMapping
